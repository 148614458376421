<template>
  <div class="md-layout-container" v-if="surveys">

      <modal  v-if="classicModal" @close="classicModalHide">  
        <template slot="header">
          <div class="p-4">
            <h4 class="modal-title">Encuesta anónima</h4>
            <p>{{surveys.question}}</p>  
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
              <md-icon>clear</md-icon>
            </md-button>
          </div>
        </template>

        <template slot="body">  
          <div  class="px-4 pb-4">

         Respuesta:
            <md-input v-model="answer"></md-input>
            <md-button class="mt-3 md-button md-primary" :disabled="!answer" @click="createAnswer()">Enviar</md-button>
         
            </div>
        </template>

        <template slot="footer">
          
        </template>
      </modal>
  </div>
</template>
<script>
import { getActiveSurveys,createAnswerSurvey } from "@/pages/Surveys/actions";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';
import  FormValidate from '@/components/FormValidate.vue';
import { Modal } from "@/components"; // Importa el componente modal


export default {
  mixins: [permissionsMixin],
  components: {
    FormValidate,
    Modal
  },

  name: "Stripe",

  data() {
    return {  
      surveys:false,
      classicModal:true,
      answer:''
    };
  },
  created() {
    this.loadinit();

  },
  methods: {
    classicModalHide() {
      this.classicModal = false;
    },
    async loadinit(){
      const response = await getActiveSurveys();
      this.surveys = response.surveys;
    },
    async createAnswer(){
      let data = {surveyId: this.surveys._id, 
                  answer: this.answer, 
                  stars: 1};
        this.classicModalHide()

         Swal.fire({
            icon: 'success',
            title: 'Gracias'
          });

      await createAnswerSurvey(data);
    }
  },
};
</script>