<template>
  <div>
    <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open">
      + Agregar Elemento
    </md-button>
    <md-table   
    v-if="!open"
      v-model="elements"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table table-striped table-hover"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <!-- Columna Nombre -->
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <router-link :to="'/element/'+item._id">
            <img v-if="item.image" :src="item.image" width="30px" alt="Element Image" class="element-image" />
            <span  class="text-primary">{{ item.name }}</span>    
          </router-link>
        </md-table-cell>
        <!-- Columna QR -->
        <md-table-cell :md-label="$t('qrCodes')">
          <router-link :to="'/element/'+item._id">
            <md-icon>
              <div :id="'qr-container-'+item._id">
                <qrcode-vue :value="'https://'+subdomain+'/element/'+item._id" :size="200"></qrcode-vue>
              </div>
            </md-icon>
          </router-link>
          <md-button class="md-just-icon md-simple md-primary" @click="printQR('qr-container-'+item._id)">
            <md-icon>download</md-icon>
            <md-tooltip md-direction="top">{{$t('download')}}</md-tooltip>
          </md-button>
        </md-table-cell>
        <!-- Columna Body Zones -->
        <md-table-cell :md-label="$t('description')">
            {{ item.description }}
        </md-table-cell>
        <md-table-cell :md-label="$t('Ejercicios')">
            <li v-for="ex in item.exercises">{{ex.name}}</li>
        </md-table-cell>

        <!-- Columna de Acciones -->
        <md-table-cell>
          <md-button class="md-primary md-icon" @click="editElement(item);open=true">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button class="md-danger md-icon" @click="confirmDelete(item)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>


    <CreateElement
      v-if="selectedElement && open"
      :key="selectedElement._id" 
      :preloadedData="selectedElement"
      @formSubmitted="onElementUpdated"
      @cancel="open=false;selectedElement=null"
    />
    <CreateElement
      v-if="!selectedElement && open"
      @formSubmitted="onElementUpdated"
      @cancel="open=false;selectedElement=null"/>
  </div>
</template>

<script>
import { getAllElementsAction, deleteElementAction } from '@/pages/Training/actions';
import CreateElement from './CreateElement.vue'; // El formulario de edición
import Swal from 'sweetalert2';
import QrcodeVue from 'qrcode-vue';
import permissionsMixin from '@/mixins/permissionsMixin';


export default {  
  mixins: [permissionsMixin],
  data() {
    return {
      elements: [],
      subdomain: '',
      open: false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      mobile: false, // Se puede configurar según sea necesario
      selectedElement: null // Elemento seleccionado para editar
    };
  },
  components: {
    CreateElement,
    QrcodeVue
  },
  created() {

      this.subdomain = window.location.hostname;
      this.start();

  },
  methods: {
    printQR(id) {
      const printContents = document.getElementById(id).innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // Esto recarga la página para restaurar el contenido original
    },
    async start(){
      try {
        const response = await getAllElementsAction();
        if (response.ok) {
          this.elements = response.elements;
        } else {
          console.error('Failed to fetch elements: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching elements: ', error);
      }
    },
    editElement(element) {
      this.selectedElement = element;
    },
    async confirmDelete(element) {
      if(this.isDemo()){
        return;
      }

      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the element "${element.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteElement(element._id);
      }
    },
    async deleteElement(id) {
      try {
        const response = await deleteElementAction(id);
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Element deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de elementos
          this.start();
      
      } catch (error) {
        console.error('Error deleting element:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onElementUpdated() {
      // Actualiza la lista de elementos después de la edición
      this.selectedElement = null;
      this.start(); // Recargar la lista
    }
  }
};
</script>

<style scoped>
.element-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.body-zone {
  display: inline-block;
  margin-right: 5px;
  padding: 2px 5px;
  background-color: #f0f0f0;
  border-radius: 3px;
}
</style>
