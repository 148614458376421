<template>
  <div class="md-layout">
    
    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="button-container">  
        <md-button v-if="!soundEnabled" @click="enableSound" class="md-info md-sm">
          <md-icon>notifications</md-icon>{{ $t('enableSoundAlerts') }}
        </md-button>
      </div>
    </div>
    <order-list 
      v-if="!loading"
      @change="changeOrderStatus" 
      :orders="resultOrdersPending" 
      :img="'/img/touch-screen.gif'" 
      :color="'primary'"
      :currency="result.currency"
      :title="$t('newOrders')">
    </order-list>

    <order-list 
      v-if="!loading"
      @change="changeOrderStatus" 
      :orders="resultOrdersOngoing" 
      :img="'/img/cooking.gif'" 
      :currency="result.currency"
      :color="'primary'"
      :title="$t('inKitchen')">
    </order-list>

    <order-list 
      v-if="!loading"
      @change="changeOrderStatus" 
      :orders="resultOrdersDone" 
      :img="'/img/alert.gif'" 
      :currency="result.currency"
      :color="'primary'"
      :title="$t('alertingCustomer')">
    </order-list>

    <order-list 
      v-if="!loading"
      @change="changeOrderStatus" 
      :orders="resultOrdersPayment" 
      :img="'/img/card.gif'" 
      :currency="result.currency"
      :color="'primary'"
      :title="$t('paymentPending')">
    </order-list>

  </div>
</template>

<script>
import { OrderList, Collapse } from "@/components";
import { getAllAction } from "@/pages/Orders/actions";

export default {
  components: {
    OrderList
  },
  created() {
    this.loadAll();
    this.connectWebSocket();
    this.startAutoRefresh();
  },
  data() {
    return {
      orderResult:[],
      result: false,
      resultOrdersPayment: [],
      resultOrdersOngoing: [],
      resultOrdersDone: [],
      reconnectInterval: 5000, // Intervalo de tiempo para intentar reconectar (5 segundos)
      resultOrdersPending: [],
      opencollapsecancel: false,
      opencollapseclose: false,
      loading: false,
      socket: false,
      previousOrdersCount: 0,
      autoRefreshInterval: null,
      soundEnabled: false,
      stablishment: '',
      lastOrder:false,
      statusList:['done','ongoing','payment','pending']
    };
  },
  computed: {
    pendingOrders() {
      return this.orderResult['pending'];
    }
  },
  beforeDestroy() { // O 'unmounted' si usas Vue 3
    this.disconnectWebSocket(); // Asegurar que cerramos la conexión cuando el componente se destruye
  },

  methods: {
    loadAll(){
      this.getNewOrders();
      this.getOrders('ongoing'); //encocina
      this.getOrders('done'); //alertando
      this.getOrders('payment'); //pendiente de pago
    },

    startAutoRefresh() {
      this.autoRefreshInterval = setInterval(() => {
        if (!this.loading) {
          this.checkNewOrder();
        }
      }, 10000);
    },
    connectWebSocket() {
      const ws = new WebSocket(process.env.VUE_APP_WEBHOOK_API_URL+'/ws/orders');
      this.socket = ws;
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.event === 'new_order') {
          const order = message.data
          if(order.status == 'pending'){
            this.lastOrder = order;
          }
          this.changeOrderS(order)
        }
      };
     
      // Conexión establecida
      ws.onopen = () => {
        let user_id = localStorage.getItem('restaurant');
        let userIdMessage = JSON.stringify({ type: 'identify', userId: user_id+'-'+Date.now() });
        ws.send(userIdMessage);
        console.log('WebSocket conectado');

        this.loadAll();
      };

      // Manejar la desconexión
      ws.onclose = () => {
        console.log('WebSocket cerrado. Intentando reconectar en 5 segundos...');
        setTimeout(() => {
          this.connectWebSocket(); // Intentar reconectar
        }, this.reconnectInterval);
      };

      // Manejar errores en la conexión
      ws.onerror = (error) => {
        console.error('Error en WebSocket:', error);
      };
    },
    disconnectWebSocket() {
      if (this.socket) {
        this.socket.close(); // Cerrar la conexión WebSocket
        console.log('Conexión WebSocket cerrada manualmente');
      }
    },
    resultOrders(status) {
      if(status == 'pending'){
        return this.resultOrdersPending
      }
      if(status == 'ongoing'){
        return this.resultOrdersOngoing
      }
      if(status == 'done'){
        return this.resultOrdersDone
      }
      if(status == 'payment'){
        return this.resultOrdersPayment
      }
    },

    async changeOrderStatus(order, newStatus) {
      this.loading = true;
      await this.changeOrderS(order);
      this.loading = false;
    },
    existsOrder(orders,order){
      let currentIndex;
      const newStatus = order.status;
      currentIndex = orders.findIndex(item => item._id === order._id);
      if (currentIndex !== -1) {
        const [movedOrder] = orders.splice(currentIndex, 1);
        movedOrder.status = newStatus;
        return movedOrder;
      }
      return false;
    },
    changeOrderS(order) {


      const newStatus = order.status;

      let currentIndex;
      let movedOrder;
      movedOrder = this.existsOrder(this.resultOrdersPending,order);
      if(!movedOrder){
        movedOrder = this.existsOrder(this.resultOrdersOngoing,order);
      }
      if(!movedOrder){
        movedOrder = this.existsOrder(this.resultOrdersPayment,order);
      }
      if(!movedOrder){
        movedOrder = this.existsOrder(this.resultOrdersDone,order);
      }

      if(!movedOrder){ //es nueva porque no entro en ninguno de los casos anteriores 
        movedOrder = order;
        this.playAlertSound()
      }
      

      if(!this.statusList.includes(newStatus)){ //es nueva porque no entro en ninguno de los casos anteriores 
     
        return;
      }

      //si llego hasta aca es porque es una orden nueva que no existia enotnces la agrego:

      if(newStatus == 'pending'){
         this.resultOrdersPending.push(movedOrder);
      }
      if(newStatus == 'done'){
         this.resultOrdersDone.push(movedOrder);
      }
      if(newStatus == 'ongoing'){
         this.resultOrdersOngoing.push(movedOrder);
      }
      if(newStatus == 'payment'){
         this.resultOrdersPayment.push(movedOrder);
      }

    

    },
    async checkNewOrder() {
      try {
        const response = await getAllAction(this.$route.params.id,['pending']);
        if(!response.ok){
          setTimeout(() => {
            location.reload();
          }, 5000);
        }
        this.resultOrdersPending = response.orders;
        if(this.resultOrdersPending.length > 0 && this.lastOrder._id && this.resultOrdersPending[0]._id != this.lastOrder._id){
              currentIndex = this.resultOrdersPending.findIndex(item => item._id === lastOrder._id);
              if(currentIndex == -1){
                window.location.reload();
              }
        }

        if(this.resultOrdersPending.length > 0 && !this.lastOrder._id){
             window.location.reload();
        }

      } catch (error) {
        this.errorMessage = error.message;
      }
    },

    async getNewOrders() {
      try {
        const response = await getAllAction(this.$route.params.id,['pending']);
        this.resultOrdersPending = response.orders;
        if(this.resultOrdersPending[0]._id){
          this.lastOrder = this.resultOrdersPending[0];
        }
        this.stablishment = response.stablishmentName;
        this.result = response.result;        
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async getOrders(status) {
      try {
        const response = await getAllAction(this.$route.params.id,[status]);
        if(status == 'pending'){
          return this.resultOrdersPending = response.orders
        }
        if(status == 'ongoing'){
          return this.resultOrdersOngoing = response.orders
        }
        if(status == 'done'){
          return this.resultOrdersDone = response.orders
        }
        if(status == 'payment'){
          return this.resultOrdersPayment = response.orders
        }
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    playAlertSound() {
      const audio = new Audio(require('@/assets/ready.mp3')); // Asegúrate de que la ruta es correcta
      audio.play().catch(error => {
        console.error('Error al reproducir звук:', error);
      });
    },
    enableSound() {
      this.soundEnabled = true;
      this.playAlertSound()
    },
  }
};
</script>

<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>