<template>
  <div>
  <FormValidate
    v-if="preloadedDataDefault"
    :inputs="formInputs"
    :requiredFields="['name', 'bodyZones']"
    buttonLabel="Save Element"
    :preloadedData="preloadedDataDefault"
    @formSubmitted="handleFormSubmit"
    @cancel="cancel()"
  >
  <div  slot="content">
    <ExerciseListClient  
      @selectedExercises="addExerciseToElement($event)" 
      :myExercises="preloadedData.exercises" />
  </div>
  </FormValidate>
  </div>

</template>

<script>
import FormValidate from '@/components/FormValidate.vue';
import { createElementAction, updateElementAction } from '@/pages/Training/actions';
import ExerciseListClient from '@/pages/Training/ExerciseListClient.vue';
import permissionsMixin from '@/mixins/permissionsMixin';
import Swal from "sweetalert2";

export default {
  mixins: [permissionsMixin],
  name: "CreateElement",
  components: {
    FormValidate,
    ExerciseListClient
  },
  props: {
    preloadedData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      openex:false,
      loading:true,
      formInputs: [
        { name: 'name', label: 'Element Name', type: 'text' },
        { name: 'description', label: 'Description', type: 'textarea' },
        { name: 'image', label: 'Image URL', type: 'image' }
      ],
      exercises:[],
      title: this.$t('create'),
      preloadedDataDefault: false,
    };
  },
  watch: {
    preloadedData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadPreloadedData(newValue);
        }
      }
    }
  },
  created(){  
    if (!this.preloadedData.exercises) {
        this.preloadedData.exercises = [];
    }
    if(!this.preloadedData._id){
      this.preloadedDataDefault = {image: 'https://gotakeit.s3.amazonaws.com/1731926368737_Capture%20d%C3%A2%C2%80%C2%99e%C3%8C%C2%81cran%202024-11-18%20a%C3%8C%C2%80%2011.39.17.png'};
    }else{
      this.preloadedDataDefault = this.preloadedData;
    }

          this.loading = false;
   
  },
  methods: {
    addExerciseToElement($event){
      this.exercises = $event;
    },
    cancel(){
      this.$emit("cancel")
    },
    loadPreloadedData(data) {
      // Asigna los valores del elemento a editar al formulario
      this.formInputs = this.formInputs.map(input => {
        if (data[input.name]) {
          input.value = data[input.name];
        }
        return input;
      });
    },
    async handleFormSubmit(formData) {
      try {
        
        if(this.isDemo()){
          return;
        }

        let response;   
        formData.exercises = this.exercises;
       
        if (this.preloadedData && this.preloadedData._id) {
        this.title = this.$t('edit');
          // Si hay preloadedData, estamos editando un elemento existente
          response = await updateElementAction(
            this.preloadedData._id,
            formData
          );
          this.cancel();
        } else {
          // Si no hay preloadedData, estamos creando un nuevo elemento
          response = await createElementAction(formData);
          this.cancel();
        }

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.preloadedData ? 'Element updated successfully' : 'Element created successfully',
            timer: 2000,
            showConfirmButton: false
          });
          this.$emit('formSubmitted');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to save element: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error saving element:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    }
  }
};
</script>
