<template>
  <div
    class="sidebar bg-info"
    :data-color="activeColor"
    :data-image="backgroundImage"
    :data-background-color="backgroundColor"
    :style="sidebarStyle"
  >
    <div class="logo">
      <a
        href="https://gotake.it"
        class="simple-text logo-mini"
        target="_blank"
      >
        <div  v-if="stablishmentReady()" class="logo-img">
          <div class="logomenu" :style="'background:url('+stablishment.logo+')'">
             
          </div>  
        </div>
        <div v-else class="logo-img">
          <img :src="logo" />
        </div>
      </a>
      <a
        href="https://gotake.it"
        class="simple-text logo-normal"
        target="_blank"
      >
        <template  v-if="stablishment && stablishment.logo" class="logo-img">{{stablishment.name}}</template>
       <div v-else>
        <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
        <template v-else>{{ title }}</template>
      </div>
      </a>
     
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import { getProfileStablishmentsAction } from "@/pages/Stablishments/actions";

export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "GO take it",
    },
    rtlTitle: {
      type: String,
      default: "GO take it",
    },
    activeColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = [
          "",
          "purple",
          "azure",
          "green",
          "orange",
          "danger",
          "rose",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: process.env.BASE_URL+"img/sidebar-2.jpg",
    },
    backgroundColor: {
      type: String,
      default: "black"
    },
    logo: {
      type: String,
      default: process.env.BASE_URL+"img/gotakeitfavicon.png",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allStablishments:false,
      stablishment: false,
    };
  },
  created() {
    this.getStablishments();
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    async getStablishments(){

      const stablishmentsResponse = await getProfileStablishmentsAction();
      const allStablishments = stablishmentsResponse.stablishments;

      if(this.stablishment){
      
      this.stablishment = allStablishments[0];

        if(this.stablishment && this.stablishment.primaryColor == '#FFFFFF' ||
        this.stablishment.primaryColor == '#ffffff' || 
        !this.stablishment.primaryColor){
          this.stablishment.primaryColor = '#333';
        }
        if(this.stablishment && this.stablishment.secondaryColor == '#FFFFFF' ||
        this.stablishment.secondaryColor == '#ffffff' || 
        !this.stablishment.secondaryColor){
          this.stablishment.secondaryColor = '#333';
        }
      }
     
      if(this.stablishmentReady() && this.stablishment){
        // Cambiar el título de la página

        document.title = this.stablishment.name;
        // Cambiar el favicon de la página
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = this.stablishment.logo;
        document.head.appendChild(link);
      }else{
          document.title = 'GO Take IT';
      }

    },
    stablishmentReady(){
        const role = localStorage.getItem('role');

        return (
          role == 'client' && 
          this.stablishment.name !== 'My Fitness' &&
          this.stablishment.name !== 'Costa Azul' && 
          this.stablishment !== undefined )
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
<style>
.logomenu{
  background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    background-color: white !important;
    width: 80px !important;
    border-radius: 40%;
    -webkit-box-shadow: 0px 0px 14px -4px;
    box-shadow: 0px 0px 14px -4px;
    display: inline-block;

    height: 37px !important;
    border-radius: 0 !important;
    border: 0 !important;
}
</style>

