<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>timeline</md-icon>
          </div>
          <h4 class="title">Recaudación</h4>
          <div>
            <h6 class="title">Solicitudes de pago</h6>
          </div>
        </md-card-header>
        <md-card-content>
          <GChart
            v-if="ready"
            type="ColumnChart"
            :data="monthlyChart.data"
            :options="monthlyChart.options"
            class="mt-4"
          />
          <div  v-else class="w-100 text-center customborder">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>

          <GChart
            v-if="ready"
            type="ColumnChart"
            :data="weeklyChart.data"
            :options="weeklyChart.options"
            class="mt-4"
          />
          <div  v-else class="w-100 text-center customborder">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { getPaymentsAction } from "@/pages/Statistics/actions";
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart,
  },
  name: "PaymentsReport",
  data() {
    return {
      ready: false,
      monthlyChart: {
        data: [],
        options: {},
      },
      weeklyChart: {
        data: [],
        options: {},
      },
    };
  },
  created() {
    this.fetchPaymentsData();
  },
  methods: {
  async fetchPaymentsData() {
    try {
      const response = await getPaymentsAction();
      const {
        monthlyReport,
        monthlyReportPending,
        weeklyReport,
        weeklyReportPending,
      } = response.data;

      // Filtrar datos inválidos
      const validMonthlyReport = monthlyReport?.filter(
        (item) => item.year !== null && item.month !== null
      ) || [];
      const validMonthlyReportPending = monthlyReportPending?.filter(
        (item) => item.year !== null && item.month !== null
      ) || [];
      const validWeeklyReport = weeklyReport?.filter(
        (item) => item.year !== null && item.week !== null
      ) || [];
      const validWeeklyReportPending = weeklyReportPending?.filter(
        (item) => item.year !== null && item.week !== null
      ) || [];

      // Verificar si hay datos válidos
      if (
        validMonthlyReport.length === 0 &&
        validMonthlyReportPending.length === 0 &&
        validWeeklyReport.length === 0 &&
        validWeeklyReportPending.length === 0
      ) {
        this.ready = false;
        return; // Salir del método si no hay datos válidos
      }

      const allMonths = [
        ...new Set(
          [
            ...validMonthlyReport.map((item) => `${item.year}-${item.month}`),
            ...validMonthlyReportPending.map((item) => `${item.year}-${item.month}`),
          ]
        ),
      ].sort((a, b) => {
        const [yearA, monthA] = a.split("-").map(Number);
        const [yearB, monthB] = b.split("-").map(Number);
        return yearA - yearB || monthA - monthB;
      });

      const labels = allMonths.map((date) => {
        const [year, month] = date.split("-");
        return `${month}/${year}`;
      });

      const monthlyPaidSeries = allMonths.map((date) => {
        const found = validMonthlyReport.find(
          (item) => `${item.year}-${item.month}` === date
        );
        return found ? found.totalPrice : 0;
      });

      const monthlyPendingSeries = allMonths.map((date) => {
        const found = validMonthlyReportPending.find(
          (item) => `${item.year}-${item.month}` === date
        );
        return found ? found.totalPrice : 0;
      });

      this.monthlyChart.data = [
        ["Mes", "Pagado", "Pendiente"],
        ...labels.map((label, index) => [
          label,
          monthlyPaidSeries[index],
          monthlyPendingSeries[index],
        ]),
      ];

      this.monthlyChart.options = {
        title: "Por Mes",
        vAxis: { title: "Monto", minValue: 0 },
        hAxis: { title: "Mes" },
        legend: { position: "top" },
        colors: ["#00bcd4", "#d81b60"],
        isStacked: true,
        bar: { groupWidth: "80%" },
      };

      const allWeeks = [
        ...new Set(
          [
            ...validWeeklyReport.map((item) => `${item.year}-W${item.week}`),
            ...validWeeklyReportPending.map((item) => `${item.year}-W${item.week}`),
          ]
        ),
      ].sort((a, b) => {
        const [yearA, weekA] = a.split("-W").map(Number);
        const [yearB, weekB] = b.split("-W").map(Number);
        return yearA - yearB || weekA - weekB;
      });

      const weeklyLabels = allWeeks.map((date) => {
        const [year, week] = date.split("-W");
        return `Semana ${week} - ${year}`;
      });

      const weeklyPaidSeries = allWeeks.map((date) => {
        const found = validWeeklyReport.find(
          (item) => `${item.year}-W${item.week}` === date
        );
        return found ? found.totalPrice : 0;
      });

      const weeklyPendingSeries = allWeeks.map((date) => {
        const found = validWeeklyReportPending.find(
          (item) => `${item.year}-W${item.week}` === date
        );
        return found ? found.totalPrice : 0;
      });

      this.weeklyChart.data = [
        ["Semana", "Pagado", "Pendiente"],
        ...weeklyLabels.map((label, index) => [
          label,
          weeklyPaidSeries[index],
          weeklyPendingSeries[index],
        ]),
      ];

      this.weeklyChart.options = {
        title: "Por Semana",
        vAxis: { title: "Monto", minValue: 0 },
        hAxis: { title: "Semana" },
        legend: { position: "top" },
        colors: ["#00bcd4", "#d81b60"],
        isStacked: true,
        bar: { groupWidth: "80%" },
      };

      this.ready = true;
    } catch (error) {
      console.error("Error fetching payments data:", error);
      this.ready = false; // Asegurarse de que `ready` no sea `true` en caso de error
    }
  },


},

};
</script>
