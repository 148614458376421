<template>
  <div>
    <FormValidate
      :inputs="formInputs"
      :requiredFields="['name', 'difficulty', 'goal', 'dayperweek']"
      buttonLabel="Save Planning"
      :preloadedData="preloadedDataModif"
      @formSubmitted="handleFormSubmit"
      @cancel="cancel()"
      class="add-rutina"
    />
  </div>
</template>

<script>
import FormValidate from '@/components/FormValidate.vue';
import { updatePlanningAction,createPlanningAction } from '@/pages/Training/actions';

import Swal from "sweetalert2";

export default {
  name: "CreatePlanning",
  components: {
    FormValidate
  },
  props: {
    preloadedData: {
      type: Object,
      default: () => ({}),
    }
  },
  created(){
  },
  data() {
    return {
      preloadedDataModif: false,
      formInputs: [
        { name: 'name', label: this.$t('name'), type: 'text' },
        { name: 'description', label: this.$t('description'), type: 'text' },
        { name: 'videoUrl', label: 'URL de video Youtube', type: 'text' },
        {
          name: 'difficulty',
          label: 'Dificultad',
          type: 'select',
          options: [
            { value: 'novato', text: 'Novato' },
            { value: 'principiante', text: 'Principiante' },
            { value: 'intermedio', text: 'Intermedio' },
            { value: 'avanzado', text: 'Avanzado' },
            { value: 'experto', text: 'Experto' }
          ],
          default: 'intermedio'
        },
        {
          name: 'goal',
          label: 'Objetivo',
          type: 'select',
          options: [
            { value: 'perderpeso', text: this.$t('perderpeso') },
            { value: 'ganarmusculo', text: this.$t('ganarmusculo') },
            { value: 'tonificacion', text: this.$t('tonificacion') },
            { value: 'movilidad', text: this.$t('movilidad') }
          ],
          default: 'movilidad'
        },
        {
          name: 'private',
          label: 'Visibilidad',
          type: 'select',
          options: [
            { value: 'private', text: 'Privada: solo la ven los usuarios que la tengan asignada' },
            { value: 'public', text: 'Pública' }
          ],
          default: 'public'
        },
        {
          name: 'duration',
          label: 'Duración',
          type: 'select',
          options: [
            { value: '1', text: '1 día' },
            { value: '2', text: '2 días' },
            { value: '3', text: '3 días' },
            { value: '4', text: '4 días' },
            { value: '5', text: '5 días' },
            { value: '6', text: '6 días' },
            { value: '7', text: '7 días' },
            { value: '10', text: '10 días' },
            { value: '14', text: '2 semanas' },
            { value: '21', text: '3 semanas' },
            { value: '30', text: '4 semanas' },
            { value: '36', text: '5 semanas' },
            { value: '48', text: '6 semanas' },
          ]
        },
        {
          name: 'dayperweek',
          label: 'Días por semana',
          type: 'select',
          options: Array.from({ length: 7 }, (_, i) => ({
            value: i + 1,
            text: `${i + 1} día${i > 0 ? 's' : ''} por semana`
          }))
        },
        { name: 'image', label: 'Image URL', type: 'image' },
      ],
    };
  },
  async created() {
    this.initializePreloadedData();
  },
  methods: {
    cancel(){
      this.$emit("cancel");
    },
    initializePreloadedData() {
      if (this.preloadedData) {
        this.preloadedDataModif = { ...this.preloadedData };
      }
    },
    async handleFormSubmit(formData) {
      try {
        let response;
        if (this.preloadedData && this.preloadedData._id) {
          // Editar un planning existente
          response = await updatePlanningAction(this.preloadedData._id, formData);
        } else {
          // Crear un nuevo planning
          response = await createPlanningAction(
            formData);
        }

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.$t('hecho'),
            timer: 2000,
            showConfirmButton: false
          });
          this.$emit('formSubmitted');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to save planning: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error saving planning:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    }
  }
};
</script>
