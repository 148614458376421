import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

// Función genérica para manejar las peticiones
const handleRequest = async (url) => {
  try {
    const { data } = await wencesApi.get(url);

    return {
      ok: true,
      data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Acceso no autorizado",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const ingressByUserAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/access/ingress-by-user`);

    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const ingressByDayAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/access/ingress-by-day`);

    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getPaymentsAction = async () => {
  return await handleRequest(`/api/stats/payments`);
};