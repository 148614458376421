<template>
  <div class="md-layout-item md-size-100 bg-white border border-radius text-black p-2">
    <div class="calendar-navigation bg-white p-3">
      <md-button class="md-sm md-primary" @click="previousMonth">❮</md-button>
       <h6>{{ $t(monthLabel) }} {{ yearLabel }}</h6>
      <md-button class="md-sm md-primary" @click="nextMonth">❯</md-button>
    </div>

    <div   class="weekcell">


      <div v-for="(day, index2) in daysInMonth" 
      :class="isPastDay(day) ? 'opaco':'bg-light'" 
      class="py-1 m-1 card daycell text-center cursor-pointer"  
      @click="choseDay(index2)">
     
        <small v-if="day.getDay()<7" class="d-none d-sm-block">{{$t(weekDays[day.getDay()])}}</small>
        
        <small v-if="day.getDay()<7" class="d-sm-none">{{getLetter(weekDays[day.getDay()])}}</small>

        <div  class="cursor-pointer" >
          <small :class="daysInMonth[index2] == actualday ? 'text-primary border-primary border border-radius':''" >{{day.getDate()}}</small>
        </div>

        <small class="itemactivity" v-if="activitiesByDay[day.getDate()].length">
          <md-icon class="text-primary">circle</md-icon>
        </small>
      
      </div>

    </div>


        <div class="w-100 mt-4 pl-1 " v-if="actualday && activitiesByDay[actualday.getDate()]">
          <h5 class="">{{$t(weekDays[actualday.getDay()])}} {{actualday.getDate()}}</h5>
        </div>

      <div class="calendar-grid-phone" v-if="actualday && activitiesByDay[actualday.getDate()]">
        <div
         v-for="(activity, activityIndex) in activitiesByDay[actualday.getDate()]" 
          :key="activityIndex"
          class="calendar-day-phone"
        >
          <md-card class="border">
            <md-card-content>

                <Activity 
                  :key="activity.planningId"
                  :activity="activity" 
                  :edit="edit"
                  :day="actualday"
                  @signUpClass="signUpClass($event)"
                  @editActivity="editActivity($event)"
                  @deleteAct="deleteAct($event)" />

            </md-card-content>
          </md-card>
        </div>
      </div>


  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import { signUpClassAction } from "@/pages/Stablishments/actions";
import { Activity } from "@/components";
import { Collapse } from "@/components";
import Swal from 'sweetalert2';
import { format, addMonths, subMonths, getDay, isToday, isBefore } from 'date-fns';

export default {
  name: "schedulecalendar",
  mixins: [permissionsMixin],
  components: {
    Activity,
    Collapse
  },
  data() {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      actualday: false
    };
  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
    phone: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    daysInMonth() {
      const date = new Date(this.currentYear, this.currentMonth, 1);
      const days = [];
      while (date.getMonth() === this.currentMonth) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    monthLabel() {
      return format(new Date(this.currentYear, this.currentMonth), 'MMMM');
    },
    monthLabelNumber() {
      return format(new Date(this.currentYear, this.currentMonth), 'MM');
    },
    yearLabel() {
      return format(new Date(this.currentYear, this.currentMonth), 'yyyy');
    },
    weekDays() {
      // Lista de días de la semana
      return ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    },

activitiesByDay() {
  const activitiesByDay = {};

  this.daysInMonth.forEach((day) => {
    const dayOfMonth = day.getDate();
    const dayOfWeek = getDay(day);

    // Actividades con fecha específica (startDate)
    const activitiesWithDate = this.activities.filter((activity) => {
      const isSameMonth = new Date(activity.startDate).getMonth() === this.currentMonth;
      const isSameYear = new Date(activity.startDate).getFullYear() === this.currentYear;
      const isSameDay = new Date(activity.startDate).getDate() === dayOfMonth;
      return activity.startDate && isSameMonth && isSameYear && isSameDay;
    });

    // Actividades recurrentes (sin startDate y que coincidan con el día de la semana)
    let recurringActivities = this.activities.filter((activity) => !activity.startDate && activity.day === dayOfWeek);

    // Filtrar recurringActivities para excluir las actividades que ya existen en activitiesWithDate como parentActivity
    recurringActivities = recurringActivities.filter((recurringActivity) => {
      // Excluir si alguna actividad en activitiesWithDate tiene como parentActivity el _id de esta actividad recurrente
      return !activitiesWithDate.some(
        (activityWithDate) => activityWithDate.parentActivity === recurringActivity._id
      );
    });

    // Combinar actividades con fecha y recurrentes
    activitiesByDay[dayOfMonth] = [...activitiesWithDate, ...recurringActivities];

    // Ordenar por hora de inicio
    activitiesByDay[dayOfMonth].sort((a, b) => a.startTime.localeCompare(b.startTime));
  });

  return activitiesByDay;
},

    
  },
  methods: {
    choseDay(day){
      this.actualday = this.daysInMonth[day]
    },
    getLetter(day){
      const d = this.$t(day);
      return d[0]
    },
    isToday(day) {
      return isToday(day);
    },
    deleteAct(id) {
      this.$emit('deleteAct', id);
    },
    editActivity(act) {
      this.$emit('editActivity', act);
    },
    isPastDay(day) {
      const today = new Date();
      return isBefore(day, today) && !isToday(day);
    },
    previousMonth() {
      const previous = subMonths(new Date(this.currentYear, this.currentMonth), 1);
      this.currentYear = previous.getFullYear();
      this.currentMonth = previous.getMonth();
    },
    nextMonth() {
      const next = addMonths(new Date(this.currentYear, this.currentMonth), 1);
      this.currentYear = next.getFullYear();
      this.currentMonth = next.getMonth();
    },
    async signUpClass(theclass) {
      const activityId = theclass.id;
      const day = theclass.day

      const role = localStorage.getItem('role');
      if (role !== 'client') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('mustLoginToSignUp'),
        });
        return;
      }

      const remainingCupos = localStorage.getItem('remainingCupos');
      const paymentType = localStorage.getItem('paymentType');
      if(!remainingCupos){
        let message;
        if(paymentType == 'month'){
          message = this.$t("noQuotaLeftThisMonth");
        }else{
          message = this.$t("noQuotaLeft");
        }

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: '0 ' + this.$t('remainingQuota'),
        });
        return;
      } 

      const userId = localStorage.getItem('userId');
      if(userId){
        
        const date = new Date(day);
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));

        // Formatear a "YYYY-MM-DDTHH:MM:SS.SSSZ"
        const formattedDate = utcDate.toISOString();

        const data = {
            userId,
            activityId,
            day: formattedDate
        }
        const response = await signUpClassAction(data);
        if(response.ok){
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            text: this.$t('allready'),
          }).then((result) => {
            this.$emit('refreshComponent');
          });
        }else{
          if(response.message == 'already'){
            response.message = 'allready'
          }
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.$t(response.message),
          });
        }
      }


    },
  },
};
</script>
<style scoped>
.cursor-pointer{
  cursor:pointer!important;
}
</style>
<style scoped>
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}

@media (max-width: 980px) {
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1px;
  }
}

.calendar-day-name {
  text-align: center;
  font-weight: bold;
}
.md-button.md-sm .md-ripple {
    font-size: 11px;
    padding: 4px !important;
}
.weekcell{
   display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}
.daycell{
  
    border:0;
    border-radius: 4px;
}
.calendar-day {
  background-color: #fff; /* Color de fondo por defecto */
}

.past-day {
  background-color: #f0f0f0; /* Color gris para los días pasados */
}
.itemactivity{
    position: absolute;
    top: -6px;
    right: -15px;
    font-size: 8px;
    border-radius: 50%;
    border: 0;
    color: #11b5c9;
    padding: 0px 9px;
    font-weight: 900;
}
.itemactivity i{
    font-size: 10px !important; 
}
.opaco{
      background-color: #d8d8d8 !important;   
    text-decoration-color: #858585;
}

.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
</style>


<style scoped>
.calendar-grid-phone {
  display: flex;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

.calendar-day-phone {
    margin-top: 4px;
  flex: 0 0 auto; /* Evita que las tarjetas se encogen y las mantiene en su tamaño original */
  margin-right: 10px; /* Espaciado entre las tarjetas */
  width: 95%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}
@media (min-width: 768px) {

.calendar-day-phone {
  width: 100%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}

.calendar-grid-phone {
  display: block;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

}


.calendar-day-phone md-card {
  width: 100%; /* Asegura que el md-card ocupe todo el ancho del contenedor */
}

/* Estilos para organizar las actividades como un calendario */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.calendar-day {
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.title {
  margin-left: 10px;
}


.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.calendar-grid .md-card {
  min-height: 200px;
}

p {
  text-align: center;
  color: #999;
}

.error {
  color: red;
  font-size: 12px;
}
.cursor-pointer{
  cursor:pointer;
}
</style>

