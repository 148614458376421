<template>
   <div class="menu">
      <!-- Editing form -->
      <div class="md-layout" v-if="menu" >
        <div class="md-layout-item md-size-50  md-small-size-100 p-3">

          <label class="md-form-label">{{ $t('menu_type') }}</label>
          <md-field>
            <md-select v-model="menu.menuType">
              <md-option value="takeaway">{{ $t('takeaway') }}</md-option>
              <md-option value="onsite">{{ $t('onsite') }}</md-option>
              <md-option value="onlymenu">{{ $t('onlymenu') }}</md-option>
              <md-option value="imagemenu">{{ $t('imagemenu') }}</md-option>
              <md-option value="shipping">{{ $t('shipping') }}</md-option>
            </md-select>
          </md-field>

          <div class="file-input" v-if="menu.menuType == 'imagemenu'">
            <label class="md-form-label">
              {{ $t('uploadMenuMessage') }}
            </label>
            <div class="button-container"  :disabled="isLoading">
              <md-button class="md-danger md-sm md-round" @click="removeAllMenuImages" v-if="menuImages.length">
                <i class="fa fa-times"></i>{{ $t('remove') }}
              </md-button>
              <md-button class="md-success md-sm md-round md-fileinput"  :disabled="isLoading">
                <template v-if="!menuImages.length"><md-icon>upload</md-icon> {{$t('uploadFile')}}</template>
                <template v-else>{{ $t('change') }}</template>
                <input type="file" accept=".png, .jpg, .jpeg, .pdf" multiple @change="onMenuImagesChange" />
              </md-button>
            </div>
            <div v-if="selectedMenuImageFiles.length">
              <div v-for="(image, index) in menuImages" :key="index">
                <template v-if="isPdf(image)">
                  <div class="pdf-preview">
                    <md-icon>picture_as_pdf</md-icon>
                    <p>{{ getFileName(selectedMenuImageFiles[index]) }}</p>
                  </div>
                </template>
                <template v-else>
                  <img :src="image" style="max-width: 100%;" />
                </template>
              </div>
            </div>
            <div v-if="this.menu.menuImages.length">
              <div v-for="(image, index) in menu.menuImages" :key="index">
                <template v-if="isPdfByUrl(image)">
                  <div class="pdf-preview">
                    <p class="text-primary"><a :href="image" target="_blank" class="text-primary"><md-icon class="text-primary">picture_as_pdf</md-icon> {{image}}</a></p>
                  </div>
                </template>
                <template v-else>
                  <img :src="image" style="max-width: 100%;" />
                </template>
              </div>
            </div>
          </div>

          <div v-if="menu.menuType != 'imagemenu'">
              <label class="md-form-label">{{ $t('products') }}</label>
              <collapse :open="false" :collapse="[$t('products')]" icon="keyboard_arrow_up" color-collapse="rose">
               <template v-slot:[`md-collapse-pane-1`]>
                <ul>
                  <li v-for="product in allProducts" :key="product._id">
                    <md-checkbox :value="!isSelected(menu, product)" @change="toggleProduct(menu, product, $event)">
                      {{ product.name }}
                    </md-checkbox>
                  </li>
                </ul>
              </template>
              </collapse>
            </div>
            <div>
            <label class="d-inline md-form-label">Menu Name</label>
            <md-field>
              <md-input v-model="menu.name" placeholder="Name" type="text"></md-input>
            </md-field>
            </div>
            <div>

            <label class="md-form-label">{{ $t('stablishments') }}</label>
            <md-field>
              <md-select v-if="menu.stablishment" v-model="menu.stablishment._id"> 
                 <md-option v-for="stabl in allStablishments" :key="stabl._id" :value="stabl._id">
                    {{ stabl.name }}
                  </md-option>
              </md-select>
              <md-select v-else v-model="menu.stablishment_id"> 
                 <md-option v-for="stabl in allStablishments" :key="stabl._id" :value="stabl._id">
                    {{ stabl.name }}
                  </md-option>
              </md-select>
            </md-field>
            </div>
            <div>
            <label v-if="false" class="md-form-label">{{ $t('request_location') }}</label>
            <md-field v-if="false">
              <md-checkbox v-model="menu.requestLocation"></md-checkbox>
            </md-field>
          </div>

          
          

        </div>

        <div class="md-layout-item md-size-50  md-small-size-100 p-3" v-if="menu.menuType != 'imagemenu'">
          <md-field>
            <md-checkbox  class="" v-model="menu.requestAdditionalInfo">
                      <span>{{ $t('request_additional_information') }}</span>
            </md-checkbox>
          </md-field>

           
          <div>
          <label class="align-left  md-form-label">{{$t('activatePaymentSystem')}}
        <span>
          <md-icon  class="md-primary">payment</md-icon>
        </span></label>
           <div class="card p-3 align-left text-left" >
            <p class="p-2 md-text text-secondary"  v-if="!stripeAccountId"><md-icon>chat</md-icon> <small>"{{$t('needToSyncPayments')}}"</small> </p>
               <p> <router-link class="md-primary p-2 md-button" :to="{ path: '/stripe' }"  v-if="!stripeAccountId">  
                  <md-icon class="md-primary">payment</md-icon>   {{ $t('syncpayments') }}
                 </router-link></p>
            <md-field>
              <md-checkbox v-model="menu.payment"></md-checkbox>
            <p class="p-2 md-text text-secondary"> <small>{{$t('customersMustPay')}}</small></p>

            </md-field>
          </div>
          </div>

          <label class="md-form-label">{{ $t('menu_languages') }}</label>
        <div class="card p-3 align-left text-left">
          <md-field>
            <md-select v-model="menu.menuLanguages" multiple  @md-selected="chooseLanguage($event,menu)">
              <md-option v-for="lang in languages" :key="lang.code" :value="lang.codelowercase">{{ $t(lang.name) }}</md-option>
            </md-select>
          </md-field>
          <div>
          <label class="md-form-label">{{ $t('menu_terms') }}</label>
          <div v-for="(translation, lang) in menu.terms" :key="lang">
            <collapse 
            v-show="languageSelected(translation.language,menu.menuLanguages)" 
            :open="false" 
            :collapse="[getLanguageName(translation.language)]" 
            icon="keyboard_arrow_up" color-collapse="rose" 
            :key="lang">
              <template v-slot:[`md-collapse-pane-1`]>
                <div class="md-layout" v-for="(value, key) in translation.values" :key="key">
                  <label class="md-layout-item md-size-15 md-xsmall-size-25 md-form-label">{{key}}</label>
                  <div class="md-layout-item">
                    <md-field>
                      <md-input v-model="menu.terms[lang].values[key]" type="text"></md-input>
                    </md-field>
                  </div>
                </div>
              </template>
            </collapse>
          </div>
        </div>
        </div>
        </div>
      </div>

      <div class="loading-container mr-2" v-if="isLoading">
        <md-progress-spinner 
          :md-diameter="20" 
          md-mode="indeterminate" 
          class="md-secondary mr-2">
        </md-progress-spinner>
      </div>
      <md-button :disabled="isLoading" class="md-primary md-sm" @click="confirmSaveAll()">Save</md-button>
      <md-button :disabled="isLoading" class="md-danger md-sm" @click="cancelEdit">Cancel</md-button>
    </div>
</template>

<script>

import Swal from 'sweetalert2';
import { Collapse } from "@/components";
import { uploadImageAction } from "@/pages/Products/actions";
import permissionsMixin from '@/mixins/permissionsMixin';

import {
  StatsCard,
} from "@/components";

export default {
  mixins: [permissionsMixin],

  props:{
    menuDefault: Object,
    allProducts: Array,
    allStablishments: Array,
  },
  created() {
    this.menu = this.menuDefault

    this.countryCodes = this.$countryCodes;
    if(this.menu.menuImages && this.menu.menuImages.length > 0){
      this.menuImages = this.menu.menuImages;
    }else{
      this.menu.menuImages = [];
    }
    this.stripeAccountId = localStorage.getItem('stripeAccountId');
  },
  computed: {
    languages() {
      return this.$languages;
    },
  },
  components: {
    Collapse,
    StatsCard
  },
  data() {
    return {  
      menu:false,
      menuImages: [],
      countryCodes:[],
      menuImageFiles: [],
      selectedMenuImageFiles: [], // Almacenará múltiples archivos de imágenes
      stripeAccountId: false,
      isLoading:false,
      newMenu: {
        name: 'Menu',
        menuType: 'onsite',
        products: [],
        requestAdditionalInfo: true,
        primaryColor: '#11b5c9',
        secondaryColor: '#6d6d6d'
      },
      translations: {
          language: 'es',
          values: {
            total: '',
            myOrder: '',
            orderNotification: '',
            name: '',
            enterName: '',
            email: '',
            enterEmail: '',
            comments: '',
            enterComments: '',
            code: '',
            phone: '',
            enterPhone: '',
            confirmOrder: '',
            yourOrder: '',
            orderDetails: '',
            sendOrder: '',
            cancel: '',
            orderNumber: '',
            preparingOrder: '',
            dontClose: '',
            orderReadyTitle: '',
            orderReadyText: '',
            welcome: '',
            instructions: ''
          }
        },
    };
  },
  methods: {
    isPdfByUrl(fileUrl) {
      return fileUrl.toLowerCase().includes('.pdf');
    },
    removeMenuImage() {
      this.menuImage = "";
      this.selectedMenuImageFile = null;
    },
    removeAllMenuImages() {
      this.menuImages = [];
      this.menu.menuImages = [];
      this.selectedMenuImageFiles = [];
    },
    onMenuImagesChange(e) {
      this.menuImages = [];
      this.menu.menuImages = [];
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.selectedMenuImageFiles = Array.from(files); // Convertimos los archivos en un array
      this.createImagePreviews(this.selectedMenuImageFiles);
    },
    createImagePreviews(files) {
      this.menuImages = []; // Reseteamos las imágenes previas
      files.forEach(file => {
        if (file.type === 'application/pdf') {
          // Si es PDF, agregar un marcador de PDF al array de menuImages
          this.menuImages.push('pdf-icon'); // solo usamos un marcador
        } else {
          // Si no es PDF, mostrar la imagen como de costumbre
          let reader = new FileReader();
          reader.onload = (e) => {
            this.menuImages.push(e.target.result); // Agregamos cada imagen al array
          };
          reader.readAsDataURL(file);
        }
      });
    },
    isPdf(file) {
      return file === 'pdf-icon'; // Usa el marcador para identificar si es un PDF
    },
    getFileName(file) {
      return file.name; // Obtiene el nombre del archivo
    },
    chooseLanguage(event, menu) {
      let language = this.translations;
      
      event.forEach(lang => {
        let isLanguagePresent = menu.terms.some(term => term.language === lang);
        if (!isLanguagePresent) {
          let newLanguage = { ...language, language: lang };
          Object.keys(newLanguage.values).forEach((key) => {
            newLanguage.values[key] = this.$t(key,newLanguage.language);
          });
          menu.terms.push(newLanguage);
        }
      });
    },
    languageSelected(code,menuLanguages){
      const language = menuLanguages.find(lang => lang === code || lang.codelowercase === code.toLowerCase());
      return language ? true : false;
    },
    getLanguageName(code) {
      return this.$getLanguageName(code);
    },
    isSelected(menu, product) {
      return menu.products.some(p => p._id === product._id);
    },
    toggleProduct(menu, product, event) {
      if (event) {
        menu.products.push(product);
      } else {
        menu.products = menu.products.filter(p => p._id !== product._id);
      }
    },
    cancelEdit() {
      this.$emit("cancel");
    },
    async uploadImage(file){
      const response = await uploadImageAction(file);
      if (response.ok) {
        let imageUrl = response.data.data.Location; // URL de la imagen subida
        return imageUrl;
      }else{
        return false;
      }
    },
    
  async confirmSaveAll() {
          if(this.isDemo()){
            return;
          }
    this.isLoading = true;
    
    try {
      // Subir las imágenes de forma paralela usando Promise.all
      let newImages = [];
      if (this.selectedMenuImageFiles.length > 0) {
        newImages = await Promise.all(
          this.selectedMenuImageFiles.map(file => this.uploadImage(file))
        );
        
        // Verificar si alguna subida falló
        if (newImages.some(img => !img)) {
          throw new Error(this.$t('errorUploadingImage'));
        }
        // Asignar las imágenes subidas al menú
        this.menu.menuImages = newImages;
      }


      // Emitir el evento save con el menú actualizado
      this.$emit("save", this.menu);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message || this.$t('errorUploadingImage'),
      });
    } finally {
      this.isLoading = false;
    }
  }
  }
};
</script>
