<template>
  <div class="md-layout">

    

    <div class="md-layout-item md-size-100 md-small-size-100 p-2" v-if="!editingMenuId">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>sensor_door</md-icon>
          </div>
          <h4 class="title">Accesos</h4>
        </md-card-header>
        <md-card-content>

        <div class="row pt-3">
         <small>Por mes</small>
          <div 
            v-for="item in months.access" 
            :key="`${item.month}-${item.year}`" 
            class="col-auto" >
            <md-button :class="selectedMonth == `${item.month}-${item.year}` ? 'md-primary':'md-secondary'" class="md-btn" @click="filter(item)">
              {{item.month}}/{{item.year}}
            </md-button>
          </div>
        </div>

        <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
          <div class="w-100 text-center customborder" v-if="orders.length == 0 && !isLoading">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>

 <md-table v-model="orders" class="showheader md-table-aligned">  
     
            
        <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell>
                <avatar v-if="item.user" :logo="item.user.avatar" :small="true"></avatar>
              </md-table-cell>
          <md-table-cell :md-label="'Acceso'" class="paymentwidth" >
            <span v-if="item.createdAt">{{ localDate(item.createdAt) }}</span>
          </md-table-cell>
          <md-table-cell :md-label="$t('accesses')" >
            <span class="badge" :class="getClass(item.status)">{{ getLabel(item.status) }}</span>
          </md-table-cell>
          <md-table-cell :md-label="$t('name')" >{{ item.buyerName }}</md-table-cell>
          <md-table-cell :md-label="$t('identifier')" >
            <span v-if="item.user"><strong>ID:</strong> {{ item.user.identifier }}</span><br>
          </md-table-cell>
          <md-table-cell :md-label="$t('paymentDate')" >
            <span v-if="item.user"><strong>{{ $t('paymentDate') }}:</strong> {{ localDate(item.user.paymentDate) }}</span>
          </md-table-cell>
          <md-table-cell :md-label="$t('email')" >{{ item.buyerEmail }}</md-table-cell>
          <md-table-cell :md-label="'Establecimiento'" >
            <span v-if="item.stablishment">{{ item.stablishment.name }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

        </md-card-content>
      </md-card>
    </div>

  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getAllAccessAction,getLastOrdersAction,getAllOrdersTodayAction,getAllAccessByMonthAction } from "@/pages/Orders/actions";

import { format } from 'date-fns';

import {
  WebSocket,
  Avatar
} from "@/components";


export default {
  created() {
    const date = new Date();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes en formato MM
          const year = date.getFullYear(); // Año en formato YYYY

          const item = {  
            month: month,
            year: year
          }
    this.filter(item);
  },
  computed: {
    languages() {
      return this.$languages;
    }
  },
  components:{
    WebSocket,
    Avatar
  },
  mounted() {
    //this.startAutoRefresh();
  },
  data() {
    return {
      months:false,
      isLoading: false,
      selectedMonth: false,
      orders:[],
      soundEnabled:false,
      loading:false,
      allProducts: [], // Productos disponibles
      editingMenuId: null, // Estado para controlar qué menú está en edición
      showNewMenuForm: false,
      lastOrder:[],
      showSaveButton: false, // Estado para controlar la visibilidad del botón "Guardar todo"
      newMenu: {
        name: ''
      },
      translations: {
          language: 'es',
          values: {
            total: '',
            myOrder: '',
            orderNotification: '',
            name: '',
            enterName: '',
            email: '',
            enterEmail: '',
            comments: '',
            enterComments: '',
            code: '',
            phone: '',
            enterPhone: '',
            confirmOrder: '',
            yourOrder: '',
            orderDetails: '',
            sendOrder: '',
            cancel: '',
            orderNumber: '',
            preparingOrder: '',
            dontClose: '',
            orderReadyTitle: '',
            orderReadyText: '',
            welcome: '',
            instructions: ''
          }
        },
    };
  },
  methods: {
    filter(item){   

          
      this.selectedMonth = `${item.month}-${item.year}`;

      this.getAll(item);
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PP HH:mm')+'hs.';  // Formato de fecha y hora según la hora local del navegador
    },
    getClass(status){
        if(status == 'cancelled'){
          return "badge-danger"
        }
        if(status == 'done'){
          return "badge-success"
        }
        return "badge-primary"
    },
    getLabel(status){
        if(status == 'cancelled'){
          return this.$t('nopaid')
        }
        if(status == 'done'){
          return this.$t('yespaid')
        }

        return this.$t('newOrders')
    },
    async getAll(item) {
      try {

        this.isLoading = true
        const result = await getAllAccessAction(item);
        this.orders = result.orders; // Suponiendo
        this.isLoading = false


        this.months = await getAllAccessByMonthAction();
    
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  }
};
</script>

<style scoped>
.rotating-border {
  width: 100px;
  height: 100px;
  margin-left:calc(50% - 50px);
  position: relative;
  text-align: center;
  line-height: 200px; /* Alinea el texto verticalmente */
}

.rotating-border::before {
  content: '';
  position: absolute;
  border-radius:50%;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 3px dashed #3498db;
  box-sizing: border-box;
  animation: rotate-border 10s linear infinite;
}

@keyframes rotate-border {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.boxwiting{
      border: 2px solid #c8c8c8;
    min-height: 300px;
    border-style: dashed;
    padding-top: 95px;
}
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
</style>
