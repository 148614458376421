<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>sensor_door</md-icon>
          </div>
          <h4 class="title">Accesos por día</h4>
          <div>
            <h6 class="title">Cantidad de accesos promedio por día</h6>
          </div>
        </md-card-header>
        <md-card-content>
          <div id="chart_div" style="width: 100%; height: 400px;"></div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ingressByDayAction } from "@/pages/Statistics/actions";

export default {
  name: "IngressByUser",
  data() {
    return {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      chartData: null,
    };
  },

  created() {
    this.loadGoogleCharts();
  },

  methods: {
    loadGoogleCharts() {
      const script = document.createElement("script");
      script.src = "https://www.gstatic.com/charts/loader.js";
      script.onload = () => {
        window.google.charts.load("current", { packages: ["corechart"] });
        window.google.charts.setOnLoadCallback(this.fetchBusinessData);
      };
      document.head.appendChild(script);
    },

    async fetchBusinessData() {
      try {
        const response = await ingressByDayAction();
        this.chartData = response.data;
        this.drawChart();
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    },

    drawChart() {
      const data = new window.google.visualization.DataTable();
      data.addColumn("string", "Día");
      data.addColumn("number", "Accesos");

      // Add rows to the chart
      const rows = this.chartData.labels.map((label, index) => [
        label,
        this.chartData.series[0][index],
      ]);
      data.addRows(rows);

      const options = {
        title: "Accesos por día",
        hAxis: { title: "Días" },
        vAxis: { title: "Cantidad de accesos", minValue: 0 },
        legend: "none",
        colors: ["#00bcd4", "#d81b60"],
      };

      const chart = new window.google.visualization.ColumnChart(
        document.getElementById("chart_div")
      );
      chart.draw(data, options);
    },
  },
};
</script>
