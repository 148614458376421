<template>
  <div class="w-100 " v-if="exercise" ref="fieldContainer">
      
    <div
      v-for="(field, index) in exerciseFields"
      :key="index"
      class="text-center mx-1 float-left"
    >

      <div class="mb-2 customback" v-if="selectedField === field.model">
        <div class="inputpopup">
          <div class="mb-2">
            <h4 class="text-secondary mt-1">{{ field.label }}</h4>
            <small>Dejalo vacio si no quieres mostrar esto a tu alumno en su rutina</small>
          </div>

          <div v-if="field.type === 'select' && field.max">
            <select
              v-model="exercise[field.model]"
              class="custominput text-center"
              @click.stop
            >
                <option :value="null">{{$t('vacio')}}</option>
              <option v-for="n in field.max" :key="n" :value="n">{{ n }}</option>
            </select>
          </div>
          <div v-if="field.type === 'select' && field.options">
            <select
              v-model="exercise[field.model]"
              class="custominput text-center"
              @click.stop
            >
                <option :value="null">{{$t('vacio')}}</option>
              <option v-for="n in field.options" :key="n" :value="n">{{ n }}</option>
            </select>
            <h4 class="d-inline">{{ field.unit }}</h4>
          </div>

          <div v-if="field.model === 'weight'" class="p-2">
            <div v-for="(item, index) in getSets(exercise)" :key="item" class="float-left m-1">
              <h5 class="d-inline">Serie {{ index + 1 }}.</h5>

              <select
                v-model="exercise[field.model][index]"
                class="custominput text-center"
                @change="updateWeight(index)"
                @click.stop
              >
                <option :value="null">{{$t('vacio')}}</option>
                <option v-for="n in field.options" :key="n" :value="n">{{ n }}</option>
              </select>
              <h4 class="d-inline">{{ field.unit }}</h4>
            </div>
          </div>

          <div v-if="field.model === 'repetitions'" class="p-2">
            <div v-for="(item, index) in getSets(exercise)" :key="item" class="float-left mt-1">
              <h5 class="d-inline">Serie {{ item }}.</h5>

              <select
                v-model="exercise.repetitions[index]"
                class="custominput text-center"
                @change="updateRepetitions(index)"
                @click.stop
              >
                <option :value="null">{{$t('vacio')}}</option>
                <option v-for="n in field.max" :key="n" :value="n">{{ n }}</option>
              </select>
              <h4 class="d-inline">{{ field.unit }}</h4>
            </div>
          </div>
        </div>
      </div>

      <button
        :class="visibility(field) ? 'btn-secondary text-black':'text-white'"
        class="btn mb-2 custominputbox"
        @click.stop="selectField(field.model)"
        v-if="source == 'custom' && (attributes || visibility(field))"
      >
        <small class="text-secondary">{{ field.label }}: </small>
        <small v-if="field.model !== 'weight' && field.model !== 'repetitions'">

          <small v-if="exercise[field.model]">{{ exercise[field.model] }}

           <span v-if="exercise[field.model] > 0">{{ field.unit }}</span>
           <span v-else><md-icon>visibility_off</md-icon></span>

          </small>
          <small v-else><md-icon>visibility_off</md-icon></small>
        </small>
        <small v-else>
          <small v-if="allElementsEqual(exercise[field.model])">


          <span v-if="exercise[field.model][0] > 0">{{ exercise[field.model][0] }} {{ field.unit }}
          </span>
          <span v-else><md-icon>visibility_off</md-icon>
          </span>

          </small>
          <small v-else>
            <small v-if="exercise['sets'] > 0">{{exercise[field.model].join(',')}}</small>
                <small v-else><md-icon>visibility_off</md-icon></small>
          </small>
        </small>
      </button>

      <div
        v-if="(exercise[field.model] && exercise[field.model] > 0)  && source !== 'custom'"
        class="btn mb-2 "
        @click.stop="selectField(field.model)"
      >
        <small class="text-secondary">{{ field.label }}: </small>
        <small v-if="field.model !== 'weight' && field.model !== 'repetitions'">

          <small v-if="exercise[field.model]">{{ exercise[field.model] }}

           <span v-if="exercise[field.model] > 0">{{ field.unit }}</span>

          </small>
        </small>
        <div v-else>
          <small v-if="allElementsEqual(exercise[field.model])">


          <span v-if="exercise[field.model][0] > 0">{{ exercise[field.model][0] }} {{ field.unit }}</span>
          <span v-else><md-icon>visibility_off</md-icon></span>

          </small>
          <small v-else>
            <small v-if="exercise['sets'] > 0">{{exercise[field.model].join(',')}}</small>
          </small>
        </div>
      </div>

    </div>
    <div class="w-100 float-left" v-if="source=='custom'">
    <span v-if="!attributes" class="cursor-pointer float-left badge badge-primary text-white" @click="attributes=true">+ atributos</span>
    <span v-if="attributes" class="cursor-pointer  float-left badge badge-primary text-white" @click="attributes=false">x cerrar</span>
    </div>
  </div>
</template>

<script>
import { updateTrainingExercisesAction } from "@/pages/Training/actions";
import Swal from "sweetalert2";

export default {
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  data() {
    return {
      attributes:false,
      exercise: false,
      edit: false,
      selectedField: null,
      exerciseFields: [
        { label: "Series", model: "sets", type: "select", max: 10, min: 1, unit: "" },
        { label: "Repeticiones", model: "repetitions", type: "selectcustom", max: 20, min: 1, unit: "" },
        { label: "Peso", model: "weight", type: "selectcustom", options: [1, 2, 5, 10, 15, 20, 25, 30, 40, 50, 100, 120, 150], unit: "kg" },
        { label: "Distancia", model: "distance", type: "select", options: [10, 20, 30, 50, 100, 150, 200, 500, 800, 1000, 2000, 5000], unit: "m" },
        { label: "Duración", model: "duration", type: "select", options: [1, 2, 3, 4, 5, 10, 15, 20, 25, 30], min: 1, unit: "min" },
        { label: "Descanso", model: "rest", type: "select", options: [10, 20, 30, 60, 120, 180], unit: "seg" },
        { label: "Velocidad", model: "speed", type: "select", options: [5, 10, 20, 30, 40, 50, 60], min: 1, unit: "km/h" }
      ]
    };
  },
  props: {
    myExercise: Object,
    source: String
  },
  created() {
    const exercise = this.myExercise;

    this.exercise = {
      ...exercise,
      repetitions: Array.isArray(exercise.repetitions) ? exercise.repetitions : [],
      weight: Array.isArray(exercise.weight) ? exercise.weight : []
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {  
    visibility(field){
        if(field.model !== 'weight' && field.model !== 'repetitions' ){
          if(!this.exercise[field.model]){
            return false;
          }else{

            if(this.allElementsEqual(this.exercise[field.model])){
              if(!this.exercise[field.model][0]){
                return true;
              }else{
               if(this.exercise['sets'] > 0){
                return false;
               }
              }
            }
          }
        }else{
        
          if((this.exercise[field.model] && this.exercise[field.model] > 0)  && this.source !== 'custom'){
            return true;
          }else{
            if(this.allElementsEqual(this.exercise[field.model]) && !this.exercise[field.model][0]){
              return false;
            }
          }
        }
        return true;
    },
    getSets(exercise){
       if(!exercise['sets']){
        return 1
       }else{
        return exercise['sets']
       }
    },
    selectField(field) {
      if(this.source !== 'custom'){
        return;
      }
      if (this.selectedField !== field) {
        this.selectedField = field;
        this.exerciseFields.forEach((field) => {
        
        });
      }
    },
    updateRepetitions(index) {
      if (index === 0) {
        const selectedValue = this.exercise.repetitions[0];
        for(let i = 0; i < this.exercise.sets; i++){
           this.exercise.repetitions[i] = selectedValue;
        }
      }
    },
    allElementsEqual(arr) {
      if(!Array.isArray(arr)){
        return true;
      }

      if (arr.length === 0) return true; // Devuelve true si el array está vacío
      return arr.every(element => element === arr[0]);
    },
    updateWeight(index) {
      if (index === 0) {
        const selectedValue = this.exercise.weight[0];
        for(let i = 0; i < this.exercise.sets; i++){
           this.exercise.weight[i] = selectedValue;
        }
      }
    },
    handleClickOutside(event) {
      const container = this.$refs.fieldContainer;
      if (this.selectedField) {
        this.selectedField = null;
        this.updateTrainingExercises();
      }
    },
    async updateTrainingExercises() {

        const slice =  this.exercise.repetitions.slice(0, (this.exercise.sets));
        this.exercise.repetitions = slice
      this.$emit("updateTraining", this.exercise);
    }
  }
};
</script>


<style scoped>
.custominputbox {
  background: #e7f4ff;
  border-radius: 4px;
  padding: 4px;
}
.custominput {
    width: 79px !important;
    border: 0;
    border-radius: 4px;
    background: #b9b9b9;
    font-size: 22px;
    margin: 4px;
}
.custominputboxanonymous {
    border-radius: 4px;
    padding: 2px;
    border: 1px solid #ebebeb;
}
.inputpopup {
    width: 391px;
    background: #fff;
    border: 1px solid #e3e0e0;
    border-radius: 5px;
    padding: 13px;
    text-align: center;
    margin-left: -196px;
    margin-top: 75px;
    float:left;
}
.customback{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
    padding-left: 50%;
  left: 0;
  background: #00000059;
  z-index: 99999;
}
</style>
