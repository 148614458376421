<template>
  <div>
    <div class="md-layout">
      <div v-if="element"  class="md-layout-item">
        <div class="float-left px-2">
         <img :src="element.image" width="200px" />
        </div>
          <h1>{{element.name}}</h1>
          <p>{{element.description}}</p>
      
      </div>
    </div>
    <div class="md-layout pt-4 p-2">
      <div class="md-layout-item">
        <h5>{{$t('Ejercicios')}}</h5>

        <div class="md-layout">
        <div v-for="item in element.exercises" class="md-layout-item md-size-50  md-small-size-100 card text-center p-3 my-3">
          <img style="margin: 0 auto;" :src="item.image" loading="lazy"  width="150px"/>
          <strong>{{ item.name }}</strong>
          <small>{{ item.description }}</small>
        </div>

      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { getAllElementsAction,getElementAction } from '@/pages/Training/actions';

import Swal from 'sweetalert2';


export default {
  data() {
    return {
      element: Object,
      subdomain: ''
    };
  },
  components: {
  },
  async created() {
    this.subdomain = window.location.hostname;
    this.element = await getElementAction(this.$route.params.id)
    this.element = this.element.element 
  },
  methods: {

    printQR(id) {
      const printContents = document.getElementById(id).innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // Esto recarga la página para restaurar el contenido original
    },
    async start(){
      try {
        const response = await getAllElementsAction();
        if (response.ok) {
          this.elements = response.elements;
        } else {
          console.error('Failed to fetch elements: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching elements: ', error);
      }
    },
    editElement(element) {
      this.selectedElement = element;
    },
    async confirmDelete(element) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the element "${element.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteElement(element._id);
      }
    },
    async deleteElement(id) {
      try {
        const response = await deleteElementAction(id);
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Element deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de elementos
          this.start();
      
      } catch (error) {
        console.error('Error deleting element:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onElementUpdated() {
      // Actualiza la lista de elementos después de la edición
      this.selectedElement = null;
      this.created(); // Recargar la lista
    }
  }
};
</script>

<style scoped>
.element-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.body-zone {
  display: inline-block;
  margin-right: 5px;
  padding: 2px 5px;
  background-color: #f0f0f0;
  border-radius: 3px;
}
</style>
