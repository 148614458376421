<template>
  <div class="p-2 importexcel">
    <h5>{{ $t('importUsers') }}</h5>
    
    <input type="file" @change="onFileChange" accept=".xlsx, .xls, .csv" />
    
    <div v-if="excelColumns.length" class="card p-2 mt-3">
      <h4>{{ $t('matchColumns') }}</h4>
      <div v-for="(field, index) in modelFields" :key="index">
        <label>{{ $t(field.label) }}</label>
        <select v-model="field.column" class="form-control">
          <option value=""> </option>
          <option v-for="col in excelColumns" :key="col" :value="col">{{ col }}</option>
        </select>
      </div>
      
      <div v-if="modelFields[2].column">
        <label>{{ $t('selectDateFormat') }}</label>
        <select v-model="selectedDateFormat" class="form-control">
          <option v-for="format in dateFormats" :key="format" :value="format">{{ format }}</option>
        </select>
      </div>
      
      <button @click="showPreviewModal" class="btn btn-primary" :disabled="isLoading || isPaused">{{ $t('previewImport') }}</button>

      <modal v-if="classicModal" @close="classicModalHide">
        
        <template slot="header">
          <div class="p-4">
            <h4 class="modal-title">{{ $t('previewUsers') }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
              <md-icon>clear</md-icon>
            </md-button>
          </div>
        </template>

        <template slot="body">
          <div class="p-4">
            <ul v-if="previewData.length">
              <li v-for="(user, index) in previewData" :key="index">
                {{ index + 1 }}. {{ $t('name') }}: {{ user.name }}, ,{{ $t('identifier') }}: {{ user.identifier }}, {{ $t('phone') }}: {{ user.phone }},{{ $t('email') }}: {{ user.email }}, {{ $t('paymentDate') }}: {{ user.paymentDate }}
              </li>
            </ul>
            <p v-if="!previewData.length">{{ $t('noDataAvailable') }}</p>
          </div>
        </template>

        <template slot="footer">
          <div class="p-4">
            <md-button class="md-primary" @click="confirmImport">{{ $t('confirmAndImport') }}</md-button>
            <md-button class="md-danger md-simple" @click="classicModalHide">{{ $t('close') }}</md-button>
          </div>
        </template>
      </modal>

      <md-progress-bar
        v-if="isLoading"
        class="md-warning"
        md-mode="buffer"
        :md-value="progressValue"
        :md-buffer="progressValue"
      ></md-progress-bar>

      <div v-if="isLoading" class="action-buttons">
        <button @click="togglePause" class="btn" :class="isPaused ? 'btn-success' : 'btn-warning'">
          {{ isPaused ? $t('resume') : $t('pause') }}
        </button>
        <button @click="cancelImport" class="btn btn-danger">{{ $t('cancel') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import * as XLSX from 'xlsx';
import moment from 'moment';
import Swal from 'sweetalert2'; // Importa SweetAlert
import { createUserAction } from "@/pages/Users/actions"; // Importa la acción de crear usuario
import { Modal } from "@/components"; // Importa el componente modal

export default {
  components: {
    Modal
  },
  data() {
    return {
      excelColumns: [],
      modelFields: [
        { label: 'name', column: '' }, // Cambiado a 'name' para traducción
        { label: 'email', column: '' }, // Cambiado a 'email' para traducción
        { label: 'paymentDate', column: '' }, // Cambiado a 'paymentDate' para traducción
        { label: 'phone', column: '' }, // Cambiado a 'paymentDate' para traducción
        { label: 'identifier', column: '' } // Cambiado a 'paymentDate' para traducción
      ],
      excelData: [],
      previewData: [], // Almacenar datos para previsualizar
      selectedDateFormat: 'D/M/YYYY',
      dateFormats: ['D/M/YYYY', 'DD/MM/YYYY', 'M/D/YYYY', 'MM/DD/YYYY'],
      isLoading: false,
      isPaused: false,
      isCancelled: false,
      progressValue: 0, // Progreso de la barra de progreso
      classicModal: false // Controlar visibilidad del modal
    };
  },
  methods: {
    excelDateToJSDate(excelSerial) {
      const excelEpoch = new Date(Date.UTC(1900, 0, 1));
      const daysOffset = excelSerial - 2;
      const jsDate = new Date(excelEpoch.getTime() + daysOffset * 24 * 60 * 60 * 1000);
      return jsDate;
    },

    onFileChange(event) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();

      const reader = new FileReader();

      reader.onload = (e) => {
        if (fileType === 'csv') {
          const csvText = e.target.result;
          this.parseCSV(csvText);
        } else {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array', raw: true });

          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelRows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

          if (excelRows.length === 0) {
            Swal.fire({
              title: this.$t('error'),
              text: this.$t('emptyFile'),
              icon: 'error',
              confirmButtonText: this.$t('ok'),
            });
            return;
          }

          if (!excelRows[0] || Object.values(excelRows[0]).some(value => value === "")) {
            Swal.fire({
              title: this.$t('error'),
              text: this.$t('missingHeaders'),
              icon: 'error',
              confirmButtonText: this.$t('ok'),
            });
            return;
          }

          this.excelColumns = excelRows[0];
          this.excelData = excelRows.slice(1);

          if (this.excelData.length > 3000) {
            Swal.fire({
              title: this.$t('limitExceeded'),
              text: this.$t('importLimit'),
              icon: 'error',
              confirmButtonText: this.$t('ok'),
            });
            this.excelData = [];
          }
        }
      };

      if (fileType === 'csv') {
        reader.readAsText(file, 'UTF-8');
      } else {
        reader.readAsArrayBuffer(file);
      }
    },

    parseCSV(data) {
      const rows = data.split('\n').map(row => row.split(','));
      const headers = rows[0];

      if (headers.some(header => !header.trim())) {
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('missingHeadersCSV'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        });
        return;
      }

      const csvData = rows.slice(1);

      this.excelColumns = headers;
      this.excelData = csvData.map(row => {
        return {
          name: row[0].trim(),
          email: row[1].trim(),
          paymentDate: this.parseDate(row[2].trim()),
          phone: row[3].trim(),
          identifier: row[4].trim(),
        };
      });

      if (this.excelData.length > 3000) {
        Swal.fire({
          title: this.$t('limitExceeded'),
          text: this.$t('importLimit'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        });
        this.excelData = [];
      }
    },
    fixEncoding(text){
      try {
        return decodeURIComponent(escape(text));
      } catch (error) {
        return "";
      }
    },    

    showPreviewModal() {
      this.previewData = this.excelData.slice(0, 5).map(row => ({
        name: this.fixEncoding(row[this.excelColumns.indexOf(this.modelFields[0].column)]),
        email: row[this.excelColumns.indexOf(this.modelFields[1].column)],
        paymentDate: this.parseDate(row[this.excelColumns.indexOf(this.modelFields[2].column)]),
        phone: row[this.excelColumns.indexOf(this.modelFields[3].column)],
        identifier: row[this.excelColumns.indexOf(this.modelFields[4].column)],
      }));

      console.log(this.excelData.slice(0, 5))



      this.classicModal = true;
    },

    classicModalHide() {
      this.classicModal = false;
    },

    async confirmImport() {
      this.classicModal = false;
      this.isLoading = true;
      this.isCancelled = false;
      this.progressValue = 0;

      const totalUsers = this.excelData.length;
      let createdUsers = 0;

      for (const row of this.excelData) {
        if (this.isCancelled) {
          Swal.fire({
            title: this.$t('importCancelled'),
            icon: 'warning',
            confirmButtonText: this.$t('ok'),
          });
          break;
        }

        while (this.isPaused) {
          await this.sleep(500);
        }

        const user = {
          name: this.fixEncoding(row[this.excelColumns.indexOf(this.modelFields[0].column)]),
          email: row[this.excelColumns.indexOf(this.modelFields[1].column)],
          paymentDate: this.parseDate(row[this.excelColumns.indexOf(this.modelFields[2].column)]),
          phone: row[this.excelColumns.indexOf(this.modelFields[3].column)],
          identifier: row[this.excelColumns.indexOf(this.modelFields[4].column)],
          role: 'client',
          password: '',
          avatar: ''
        };

        try {
          await createUserAction(user);
          createdUsers += 1;
          this.progressValue = (createdUsers / totalUsers) * 100;
        } catch (error) {
          console.error(this.$t('errorCreatingUser', { name: user.name }), error);
        }
      }

      if (!this.isCancelled) {
        this.isLoading = false;
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('importedUsers', { createdUsers }),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        }).then(() => {
          this.$emit("reload");
        });
      }
    },

    togglePause() {
      this.isPaused = !this.isPaused;
    },

    cancelImport() {
      this.isCancelled = true;
      this.isLoading = false;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    parseDate(dateValue) {
      if(!dateValue){
        return dateValue;
      }
      // Detectar el formato de fecha seleccionado y dividir en partes
      const parts = dateValue.split('/');
      let day, month, year;

      if (this.selectedDateFormat === 'D/M/YYYY') {
        [day, month, year] = parts;
      } else if (this.selectedDateFormat === 'DD/MM/YYYY') {
        [day, month, year] = parts;
      } else if (this.selectedDateFormat === 'M/D/YYYY') {
        [month, day, year] = parts;
      } else if (this.selectedDateFormat === 'MM/DD/YYYY') {
        [month, day, year] = parts;
      } else {
        return dateValue; // Retornar sin cambios si no es un formato esperado
      }

      // Asegurar que el día y el mes tienen dos dígitos
      day = day.padStart(2, '0');
      month = month.padStart(2, '0');

      // Formatear la fecha en YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;

      // Verificar si la fecha es válida
      if (moment(formattedDate, 'YYYY-MM-DD', true).isValid()) {
        return formattedDate;
      }

      // Retorna el valor original si no se puede analizar
      return dateValue;
    }

  }
};
</script>


<style scoped>
.form-control {
  margin-bottom: 10px;
}
.md-progress-bar {
  margin-top: 20px;
  width: 100%;
}
.action-buttons {
  margin-top: 20px;
}
.btn {
  margin-right: 10px;
}
</style>
