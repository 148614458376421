<template>
 <div class="md-layout">
 <div class="md-layout-item md-small-size-100">
  <FormValidate
    :inputs="formInputs"
    :requiredFields="['name']"
    :buttonLabel="$t('save')"
    :formTitle="titleForm"
    :preloadedData="preloadedData"
    @formSubmitted="handleFormSubmit"
    @cancel="cancel()"
    v-if="preloadedData">
    <ExerciseListClient  
     slot="content"
      @selectedExercises="addExerciseToTraining($event)" 
      :myExercises="preloadedData.myexercises" />
    </FormValidate>
  </div>
  </div>
               
</template>

<script>
import { createTrainingAction, updateTrainingAction, getAllExercisesAction } from '@/pages/Training/actions';
import Swal from "sweetalert2";
import  ExerciseListClient from '@/pages/Training/ExerciseListClient.vue';
import  FormValidate from '@/components/FormValidate.vue';

export default {
  name: "CreateTraining",
  components: {
    FormValidate,
    ExerciseListClient
  },
  props: {
    preloadedData: {
      type: Object,
      default: () => ({name:'Día de entrenamiento'}),
    },
    planningId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      titleForm: this.$t('create'),
      formInputs: [
        { name: 'name', label: this.$t('name'), type: 'text' }
      ],
    };
  },
  async created() {
  },
  methods: {
    cancel(){
      this.$emit("cancel")
    },
    addExerciseToTraining($event){
      this.exercises = $event;
    },
    close() {
      this.$emit('formSubmitted',null);
    },
    async handleFormSubmit(formData) {
    
        formData.myexercises = this.exercises
      


      try {
        let response;
        if (this.preloadedData && this.preloadedData._id) {
          // Si hay preloadedData, estamos editando un entrenamiento existente
          response = await updateTrainingAction(
            this.preloadedData._id,
            formData
          );
        } else {
         if(this.planningId){
          formData.planning = this.planningId

         }
          // Si no hay preloadedData, estamos creando un nuevo entrenamiento
          response = await createTrainingAction(formData);
        }

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.preloadedData ? 'Training updated successfully' : 'Training created successfully',
            timer: 2000,
            showConfirmButton: false
          });
          this.$emit('formSubmitted',response);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to save training: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error saving training:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    }
  }
};
</script>

<style>
.md-card .exerciselistclient img {
    width: 50px !important;
    height: inherit;
}
</style>
