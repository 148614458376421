import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";
import { router } from '@/main'; // Asegúrate de que la ruta a tu main.js es correcta


export const getAllUsersAction = async () => {
  try {


    let url = `/api/users/all`

    const { data } = await wencesApi.get(url);

    return {
      ok: true,
      users: data.data,
      pagination: data.pagination
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Users no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllClientsAction = async (page,search=null) => {
  try {

    const queryString = new URLSearchParams(search).toString();


    let url = `/api/users/all?role=client&sort=name`
    if(page){
       url = `/api/users/all?role=client&all?page=${page}&pagination=true&sort=name`;
    }
    if(search){
          url = `/api/users/all?role=client&all?page=1&pagination=true&${queryString}&sort=name`;
    }
    const { data } = await wencesApi.get(url);

    return {
      ok: true,
      users: data.data,
      pagination: data.pagination
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Users no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllAdminAction = async (page,search=null) => {
  try {

    let url = `/api/users/all?role=teacher,gym`
    if(page){
       url = `/api/users/all?role=teacher,gym&page=${page}&pagination=true`;
    }
    if(search){
          url = `/api/users/all?role=teacher,gym&page=1&pagination=true&name=${search}`;
    }
    const { data } = await wencesApi.get(url);

    return {
      ok: true,
      users: data.data,
      pagination: data.pagination
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Users no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getUserAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/users/identifier/${id}`);

    return {
      ok: true,
      user: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Users no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getMyProfileAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/users/myprofile`);

    const profile = JSON.stringify(data);
    localStorage.setItem("profile",profile);

 
    return data;
    
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Users no existe",
      };
    }
      router.push('logout');
    

    throw new Error("No se pudo realizar la petición");
 

  }
};


export const markAsPaidAction = async (userId,status) => {
  try {
    const {data} = await wencesApi.post("/api/users/markaspaid", {userId,status});

    return {
      ok: true,
      message: data.message,
      status: data.status
    };
  } catch (error) {
    return {
      ok: false,
      message: "error",
    };
  }
};

export const assignPlanAction = async (config,users) => {
  try {
    const { result } = await wencesApi.post("/api/users/asignplan", {config,users});

    return {
      ok: true
    };
  } catch (error) {
    return {
      ok: false,
      message: "error",
    };
  }
};


export const deleteUserAction = async (userId) => {
  try {
    const { result } = await wencesApi.post("/api/users/delete", {userId: userId});

    return {
      ok: true
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo crear el usuario",
    };
  }
};

export const createUserAction = async (user) => {
  try {
    const result  = await wencesApi.post("/api/users/registeradmin", user);
    
    return {ok: result.data.ok,
          message: result.data.message};

  } catch (error) {
    return {
      ok: false,
      message: "No se pudo crear el usuario",
    };
  }
};

export const createUserAnonymousAction = async (user) => {
  try {
    const { data } = await wencesApi.post("/api/users/registeranonymous", user);
    return {
      ok: data.ok,
      message:data.message
    };

  } catch (error) {
    return {
      ok: false,
      message: "No se pudo crear el usuario",
    };
  }
};