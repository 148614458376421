
<template> 
  <div class="register-page register-welcome md-layout">
    <div class="md-layout-item">
      <signup-card>
        <div slot="title" class="logoregister mb-4">
          <img :src="'./img/gotakeit.png'" :title="$t('logoTitle')" />
            
        </div>
                  <h3 class="title text-center mb-4" slot="title">{{ $t('register') }}</h3>
         

        <div
          class="md-layout-item  text-center p-4 md-size-50 md-medium-size-50 md-small-size-100 ml-auto "
          slot="content-left"
        >
          <h5>{{$t('digitalQrMenu')}}</h5>
            <router-link class="w-100 px-4 py-2 md-button md-primary md-lg" :to="{ path:  'register/food' }">

          <md-icon>restaurant</md-icon>
             {{ $t('getStarted') }}
            </router-link>
        


          <div class="card">
            <img :src="'/img/restogotakeit.jpg'" />
          </div>
        </div>

        <div
          class="md-layout-item p-4 text-center md-size-50 md-medium-size-50 md-small-size-100 ml-auto "
          slot="content-right"
        >
        <h5>{{$t('accessControl')}}</h5>
          
            <router-link class="w-100 px-4 py-2 md-button md-primary md-lg" :to="{ path:  'register/gym' }">

          <md-icon>fitness_center</md-icon>
             {{ $t('getStarted') }}
            </router-link>
          <div class="card">
              <img :src="'./img/gymgotakeit.jpg'" />
          </div>
        </div>
        
      </signup-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { registerAction, checkNameAction } from "@/pages/Auth/actions";
import { SignupCard } from "@/components";
import locales from '../../../locales/index.js';
import { i18n } from "@/i18n";

export default {
  components: {
    SignupCard,
  },
}
</script>

<style>

.register-welcome .md-card-signup .md-card-content{
        padding: 0px 10px 0px 10px !important;
    }

</style>
