 <template>
  <div class="md-layout">
    <!-- Formulario para agregar una actividad -->
    
    <div class="md-layout-item md-small-size-100" v-if="!phone && edit">
      <md-card>

        <md-card-content> 
          <collapse
           
              id="collapsetotal23"
              :collapse="[$t('addButton')]"
              description="Una actividad puede ser clases o diferentes eventos que se desarrollan en el establecimiento donde tus clientes quieran participar. 'Crossift', 'Yoga', 'Fiesta de fin de año', etc."
              icon="keyboard_arrow_up"
              :open="open"
              class="bg-white">
               <template slot="md-collapse-pane-1">
              

            <h5 v-if="newActivity._id">{{$t('edit')}} {{newActivity.name}}</h5>
          <div class="md-layout">



            <div class="md-layout-item float-left md-size-100 ">
              <md-field>
                <label>{{ $t('activityLabel') }}</label>
                <md-input v-model="newActivity.name" :placeholder="$t('activityPlaceholder')" />
                <span v-if="errors.name" class="error">{{ $t('nameRequired') }}</span>
              </md-field>
            </div>
                <md-checkbox v-model="specificDate" class="">
                  <label class="pt-2">{{ $t('defineSpecificDate') }}</label>
                </md-checkbox>
      

            <div class="md-layout-item float-left md-size-100  text-left">
                <div class="md-size-100" v-if="specificDate">
                      <md-datepicker v-model="startDate">
                        <label>Select date</label>
                      </md-datepicker>
                </div>
                <div class="md-size-100" v-if="!specificDate">
                  <md-field >
                    <label>{{ $t('dayOfWeekLabel') }}</label>
                    <md-select v-model="newActivity.day">
                      <md-option v-for="(day, index) in daysOfWeek" :key="index" :value="index">Todos los {{ $t(day) }}</md-option>
                    </md-select>
                    <span v-if="errors.day" class="error">{{ $t('dayRequired') }}</span>
                  </md-field>
                </div>
            </div>

            <div class="md-layout-item float-left md-size-100  text-left">
               
                <div class="md-size-100">
                  <md-field >
                    <label>Rutina</label>
                   <md-select v-model="newActivity.planningId" >
                      <md-option :value="false">- Vacio -</md-option>
                      <md-option v-for="(item, index) in plannings" :key="item._id" :value="item._id">{{ item.name }}</md-option>
                    </md-select>
                    <span v-if="errors.day" class="error">{{ $t('dayRequired') }}</span>
                  </md-field>

                      <small >Puedes ligar una rutina a esta actividad y de esa forma tus clientes podrán seguir el plan de trabajo.</small>
                </div>
            </div>

            <div class=" md-size-100 " >
                <md-checkbox v-model="newActivity.registration">
                  <label class="pt-2">{{ $t('enableRegistrations') }}</label>
                </md-checkbox> 
            </div>


            <div class=" md-size-100 card w-100  p-2" v-if="newActivity.registration">

             
              <div class=" md-size-100 mt-0 pt-0" >
                <md-checkbox v-model="newActivity.consumequota">
                  <label class="pt-2"> Solo clientes con clases disponibles pueden inscribirse</label>
                </md-checkbox> 

              <div class="w-100">
                <small>
                 {{$t('allowOverQuotaMessage')}}
                </small>
              </div>
              </div>
              <div class=" md-size-100 mt-0 pt-0" >
                  <md-checkbox v-model="unlimitedQuota" @change="unlimitedQuotaChange()">
                    <label class="pt-2">{{$t('unlimitedQuota')}}</label>
                  </md-checkbox> 
              </div>

              <strong v-if="!unlimitedQuota">{{$t('quota')}}: </strong>
              <small v-if="!unlimitedQuota">{{$t('quotaMessage')}} <span class="text-primary">https://{{subdomain}}.app.gotake.it/#/schedule.</span> 
              </small>
              <md-field v-if="!unlimitedQuota">
                <label>{{$t('quota')}}</label>
                <md-input type="Number" v-model="newActivity.cupos" />
              </md-field>
            </div>

           
         

            <div class=" md-layout-item  float-left md-size-100 pl-2">
              <md-field>
                <label>{{ $t('startTimeLabel') }}</label>
                <md-input type="time" v-model="newActivity.startTime" />
                <span v-if="errors.startTime" class="error">{{ $t('startTimeRequired') }}</span>
              </md-field>

              <md-field>
                <label>{{ $t('endTimeLabel') }}</label>
                <md-input type="time" v-model="newActivity.endTime" />
              </md-field>
            </div>



          </div>
          <md-button v-if="!newActivity._id" class="md-raised md-primary" @click="saveAllActivity"  :disabled="!validateFields()">{{ $t('addButton') }}</md-button>
          <md-button v-else class="md-raised md-primary" @click="saveAllActivity" :disabled="!validateFields()">{{ $t('save') }}</md-button>
          <md-button v-if="newActivity._id" class="md-raised md-danger" @click="cancel()">{{ $t('cancel') }}</md-button>
             </template>
              </collapse> 
        </md-card-content>
      </md-card>
    </div>

      <ScheduleCalendar 
      :activities="activities" 
      :phone="phone" 
      :edit="edit"
      @refreshComponent="refreshComponent()"
      @editActivity="editActivity($event)"
      @deleteAct="deleteAct($event)">
      </ScheduleCalendar>


  </div>

</template>

<script>
import { saveActivity,getActivity,deleteActivity,getAllAction } from "@/pages/Stablishments/actions";
import { getAllPlanningsAction } from '@/pages/Training/actions';
import permissionsMixin from '@/mixins/permissionsMixin';
import Swal from 'sweetalert2';
import { Collapse,ScheduleCalendar} from "@/components";

export default {
  components: {
    Collapse,
    ScheduleCalendar
  },
  mixins: [permissionsMixin],

  data() {
    return {
      startDate:false,
      unlimitedQuota: false,
      specificDate: false,
      activities:[],
      open:false,
      plannings:[],
      subdomain: window.location.hostname,
      allStablishments: [],
      newActivity: {
        consumequota: false,
        name: '',
        day: 1, // Cambiado a número (0 = Domingo, 1 = Lunes, etc.)
        startTime: '10:00',
        endTime: '11:00',
      },
      errors: {
        name: false,
        day: false,
        startTime: false,
      },
      daysOfWeek: ['Domingo','Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
    };
  },
  async created() {
    this.getAll();
    this.getAllPlannings();
  },
  props: {
    phone: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    completeview: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    qr: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    edit: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
  },
  computed: {
    activitiesByDay() {
      const sortedActivities = this.activities.reduce((acc, activity) => {
        if (!acc[activity.day]) {
          acc[activity.day] = [];
        }
        acc[activity.day].push(activity);
        return acc;
      }, {});

      // Ordenar las actividades por la hora de inicio
      Object.keys(sortedActivities).forEach(day => {
        sortedActivities[day].sort((a, b) => a.startTime.localeCompare(b.startTime));
      });

      return sortedActivities;
    }
  },
  methods: {   
    async getAllPlannings(){
      const response = await getAllPlanningsAction();
      if (response.ok) {
        this.plannings = response.plannings;
      } else {
        console.error('Failed to fetch plannings: ', response.message);
      }
    },
    formatDate(startDate) {
        // Creamos un nuevo Date con los mismos valores del parámetro
          const date = startDate;

          // Extraemos el año, mes y día directamente sin realizar conversiones de zonas horarias
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0-11, sumamos 1
          const day = String(date.getDate()).padStart(2, '0');

          // Retornamos la fecha en formato YYYY-MM-DD
          return `${year}-${month}-${day}`;
      
    },
    cancel(){
      this.open=false
      this.newActivity = {
        name: '',
        day: 1, // Cambiado a número (0 = Domingo, 1 = Lunes, etc.)
        startTime: '10:00',
        endTime: '11:00',
        consumequota: false,
      }
    },
    unlimitedQuotaChange(){
      if(!this.unlimitedQuota){
        this.newActivity.cupos = 10;
      }else{
         this.newActivity.cupos = -1;
      }
    },
    refreshComponent(){
      this.$emit("refreshComponent")
    },
    validateFields() {
      this.errors.name = !this.newActivity.name;
      this.errors.startTime = !this.newActivity.startTime;

      return !this.errors.name && !this.errors.day && !this.errors.startTime;
    },
    async saveAllActivity(){
        if(!this.specificDate){
          this.newActivity.startDate = null;
        }else{
          let startdate = this.formatDate(this.startDate);
          this.newActivity.startDate = startdate;
        }    

        if(this.unlimitedQuota){
          this.newActivity.cupos = -1;
        }

        await saveActivity(this.newActivity);
         Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK',
        });

        this.getAll();


    },
    async deleteAllActivity(id){
            await deleteActivity(id);
            this.getAll();
    },
    deleteAct(id){
        if(this.isDemo()){
          return;
        }
        Swal.fire({
          title: this.$t('areYouSure'),
          text: this.$t('youWontBeAbleToRevertThis'),
          type: "warning",
          position: 'top',
          confirmButtonText: this.$t('delete'),
          showCancelButton: true
        }).then((result) => {
          if(result.isConfirmed){ 
            this.deleteAllActivity(id);
          }
        });
    },
    async editActivity(act){
      if(act.startDate){
        this.startDate = new Date(act.startDate);
        this.specificDate = true;
      }else{
        this.specificDate = false;
      }

      if(act.cupos == -1){
        this.unlimitedQuota = true;
      }else{
        this.unlimitedQuota = false;
      }

        this.open = true;
      this.newActivity = act
    },
    async getAll() {
        try {

          const data = await getActivity();
          this.activities = data.activities;


          this.cancel()


          const stablishmentsResponse = await getAllAction();
          this.allStablishments = stablishmentsResponse.stablishments

        } catch (error) {
          this.errorMessage = error.message;
        }
      },
  },
};
</script>
