<template>

  <div class="w-100 float-left p-2 exerciselistclient" v-if="!loading">
    <h5 v-if="selectedExercises.length > 0">Seleccionados</h5>
    <md-table 
      v-if="selectedExercises.length > 0"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table  paginated-table-exercises mb-4 theadnone"
    >
     
      <md-table-row 
        slot="md-table-row"  
        v-for="(item,index) in selectedExercises"
      >
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <md-button class="md-button md-danger  mt-2  md-icon" @click="removeExerciseFromSelected(item)">
            <md-icon>close</md-icon>
          </md-button>
          <img :src="item.image" loading="lazy" width="50px"/>
          <div>
            <div>
            <strong>{{ item.name }}</strong>
            </div>
            <div>
              <span v-for="body in item.bodyZone" class="pl-2"> {{ body }}</span>
            </div>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <h5>{{$t('select')}} </h5>

   <div class="card w-100 p-4 mb-2">
      <h6>Buscar</h6>
      <md-field>
        <label>{{ $t('bodyZone') }}</label>
        <md-select v-model="selectedBodyZone" @change="filterExercises">
          <md-option v-for="zone in bodyZones" :key="zone" :value="zone">{{  $t(zone) }}</md-option>
        </md-select>
      </md-field>
      <md-field>
        <label>{{ $t('elements') }}</label>
        <md-select v-model="selectedElements" @change="filterExercises">
          <md-option :value="''">Todas</md-option>
          <md-option v-for="element in elements" :key="element" :value="element">{{  $t(element) }}</md-option>
        </md-select>
      </md-field>
      <md-field>
        <label>{{ $t('name') }}</label>
        <md-input type="text" v-model="searchQuery" @change="filterExercises"></md-input>
      </md-field>
    </div>

    <md-table 
      v-model="filteredExercises"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table paginated-table-exercises exerciselist"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <md-button class="md-button md-primary  mt-2  md-icon" @click="addExerciseToTraining(item)">
            <md-icon>add</md-icon>
          </md-button>
          <img :src="item.image" loading="lazy" width="50px"/> 
          <div>
          <div>
          <strong>{{ item.name }}</strong>
          </div>
          <div> 
            <span v-for="body in item.bodyZone" class="pl-2"> {{  body }}</span>
          </div>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

  </div>
</template>

<script>
import { getAllExercisesAction, deleteExerciseAction,getExerciseElementsAction,getBodyZonesElementsAction } from '@/pages/Training/actions';
import Swal from 'sweetalert2';

export default {
  name: "ExerciseListClient",
  data() {
    return {
      loading:true,
      exercises: [],
      currentSort: 'name',
      searchQuery:'',
      currentSortOrder: 'asc',
      mobile: false,
      bodyZones: [], // Opciones de zonas
      selectedBodyZone: 'Cardio', // Valor por defecto del primer bodyZone
      selectedElements: '', // Valor por defecto del primer element
      elements: [],
      selectedExercise: null, // Ejercicio seleccionado para editar
      selectedExercises: [], // Ejercicios seleccionados
      selectedExercisesId: [] // IDs de los ejercicios seleccionados
    };
  },
  computed: {
    filteredExercises() {
      return this.exercises.filter(exercise => {
        const matchesZone = !this.selectedBodyZone || exercise.bodyZone.includes(this.selectedBodyZone);
        const matchesElements = !this.selectedElements || exercise.elements.includes(this.selectedElements);
        const matchesQuery = !this.searchQuery || exercise.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        if(this.selectedElements){
          this.selectedBodyZone = '';
        }

        if(this.searchQuery.length > 1){
          return matchesQuery;
        }else{
          return matchesZone && matchesQuery && matchesElements
        }

      });
    }
  },
  async created() {



    const optionElements =  await getExerciseElementsAction();
    this.elements = optionElements.elements;

    const bodyzones = await getBodyZonesElementsAction();
    this.bodyZones = bodyzones.result;

    await this.start();

    // Si se pasan IDs en 'myExercises', cargarlos como seleccionados
    if (this.myExercises && this.myExercises.length > 0) {
      this.preselectExercises(this.myExercises);
    }
  },
  props: {
    myExercises: {
      type: Array,
      default: () => []
    },
  },
  methods: {

    filterExercises(){
      this.selectedBodyZone;
    },
    preselectExercises(exerciseIds) {
      // Ordena los ejercicios en `selectedExercises` según el orden de `exerciseIds`
      //this.selectedExercises = exerciseIds
       // .map(exer => this.exercises.find(exercise => exercise._id === exer.id))
       // .filter(exercise => exercise); // Filtra valores nulos por si algún ID no coincide
      this.selectedExercises = exerciseIds;
      this.$emit('selectedExercises', this.selectedExercises);
    },
    addExerciseToTraining(exercise) {
      // Agregar ejercicio a la lista de seleccionados
      if (!this.selectedExercises.some(e => e._id === exercise._id)) {
        this.selectedExercises.push(exercise);
        this.selectedExercisesId.push(exercise._id);
        this.$emit('selectedExercises', this.selectedExercises);
      }
    },
    removeExerciseFromSelected(exercise) {
      // Eliminar ejercicio de la lista de seleccionados
      this.selectedExercises = this.selectedExercises.filter(e => e._id !== exercise._id);
      this.selectedExercisesId = this.selectedExercisesId.filter(id => id !== exercise._id);
      this.$emit('selectedExercises', this.selectedExercises);
    },
    async start() {
      try {
        const response = await getAllExercisesAction();
        if (response.ok) {
          this.exercises = response.exercises;

          // Si hay ejercicios preseleccionados, hacer la preselección
          if (this.myExercises && this.myExercises.length > 0) {
            this.preselectExercises(this.myExercises);
          }
          this.loading = false;
        } else {
          console.error('Failed to fetch exercises: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching exercises: ', error);
      }
    },  
    async confirmDelete(exercise) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the exercise "${exercise.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteExercise(exercise._id);
      }
    },
    async deleteExercise(id) {
      try {
        const response = await deleteExerciseAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Exercise deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de ejercicios
          this.start();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete exercise: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting exercise:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onExerciseUpdated() {
      // Actualiza la lista de ejercicios después de la edición
      this.selectedExercise = null;
      this.start(); // Recargar la lista
    }
  }
};
</script>
<style>
.paginated-table-exercises .md-table-cell:last-child .md-table-cell-container, 
.paginated-table-exercises .md-table-head:last-child {
    justify-content: normal !important;
    text-align: left !important;
}

.theadnone thead{
  display:none;
}
.customborder {
    border: 1px solid #c4c0c0;
    border-radius: 5px;
    border-style: dashed;
}
</style>
<style scoped>
.customborder {
  margin-top:10px;
}

.width56{
  width:56px;
}

.exerciselist{
max-height: 300px;
    height: 200px;
    overflow-y: scroll;
}
.selected-exercises {
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 5px;
  background-color: #f9f9f9;
}
</style>
