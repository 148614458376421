<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-rose">
      <div class="card-icon">
        <md-icon>qr_code</md-icon>
      </div>
      <router-link class="md-primary" :to="{ path: '/qrs'}">
        <h4 class="title">{{ $t('qrCodes') }}</h4>
      </router-link>
      <p class="p-2 md-text text-secondary" v-if="isRestaurant()">
        <md-icon>chat</md-icon> "{{ $t('qrhelp') }}"
      </p>
    </md-card-header>
    <md-card-content>
      <!-- Agrupamos los ítems de 4 en 4 -->
      <CarouselItem :id="'qrCarousel'" :items="groupedQrs">
        <template #default="slotProps">
          <div>
            <!-- Iteramos sobre cada ítem dentro del grupo -->
            <div
              v-for="item in slotProps.item"
              :key="item._id"
              class="md-layout-item md-small-size-50 md-small-size-50 md-medium-size-50 md-size-25 float-left text-center p-2 pt-4"
            >
              <div class="card p-2" 
              :class="item._id == selected && isRestaurant() ? 'border-primary iphoneitemselected':''">


                <h6>{{ item.name }}</h6>
                <div class="qrcode">
                <small v-if="isRestaurant()">{{item.menu.name}}</small>
                  <router-link
                    class="md-primary"
                    :to="{ path: '/menu/' + item._id }"
                    v-if="isRestaurant()"
                  >
                    <div :id="'qr-container-' + item._id">
                      <qrcode-vue
                        :value="'https://' + subdomain + '/menu/' + item._id"
                        :size="200"

                      ></qrcode-vue>
                    </div>
                  </router-link>

                  <router-link
                    class="md-primary"
                    :to="{ path: '/accessqr/' + item._id }"
                    v-if="isGym()"
                  >
                    <div :id="'qr-container-' + item._id">
                      <qrcode-vue
                        :value="'https://' + subdomain + '/accessqr/' + item._id"
                        :size="200"
                         @ready="setBase64($event, item._id)"
                      ></qrcode-vue>
                    </div>
                  </router-link>
                </div>
                <small class="text-secondary">{{ $t('scanphone') }}</small>
                <md-button
                  slot="footer"
                  @click="downloadQRCode('qr-container-' + item._id,item.name)"
                  class="md-success md-round md-sm"
                >
                  {{ $t('download') }}
                  <md-icon>download</md-icon>
                </md-button>
                <md-button
                   v-if="isRestaurant()"
                  slot="footer"
                  @click="selectItem(item)"
                  class="md-primary md-round md-sm"
                >
                  {{ $t('viewMenu') }}
                  <md-icon>visibility</md-icon>
                </md-button>
                <router-link
                    class="md-primary text-primary"
                    :to="{ path: '/accessqr/' + item._id }"
                    v-if="isGym()"
                  >
                  <md-icon 
                    class="md-primary text-primary" >visibility</md-icon>
                </router-link>

              </div>
            </div>
          </div>
        </template>
      </CarouselItem>
    </md-card-content>
  </md-card>
</template>
<script>
import QrcodeVue from 'qrcode-vue';
import { getAllQrAction, saveAllAction } from "@/pages/Qrs/actions";
import { getAllMenuAction } from "@/pages/Menu/actions";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';
import { CarouselItem } from "@/components"; // Importamos el mismo componente Carousel


import introJs from 'intro.js';
import 'intro.js/introjs.css';

export default {
  mixins: [permissionsMixin],
  name: "qr-block",
  components: {
    QrcodeVue,
    CarouselItem
  },
  created() {
    this.subdomain = window.location.hostname;
    this.getAll();

    if(this.isDemoGym()){
      this.tutorialQR();
    }
  },
  data() {
    return {
      selected:false,
      subdomain: false,
      jsonData: {
        qrs: []
      },
      menus: [],
      showNewQrForm: false,
      showSaveButton: false,
      newQr: {
        name: '',
        menuId: ''
      },
      base64Qrs: {}, // Objeto para almacenar los QR base64 por ID
      editingQrId: null,
      originalName: ''
    };
  },
  computed: {
    groupedQrs() {
      // Agrupamos los códigos QR en grupos de 4
      const chunkSize = 4;
      const grouped = [];
      for (let i = 0; i < this.jsonData.qrs.length; i += chunkSize) {
        grouped.push(this.jsonData.qrs.slice(i, i + chunkSize));
      }
      return grouped;
    }
  },
  methods: {

     tutorialQR() {
            setTimeout(() => {

                introJs()
                  .setOptions({
                     showProgress: false,
                    nextLabel: 'Siguiente',
                    prevLabel: 'Atrás',
                    doneLabel: 'Listo',
                    showBullets: false,
                    steps: [
                      {
                        title: 'Bienvenido 👋',
                        element: document.querySelector('.qrcode'),
                        intro: 'Empezar a usar GO Take IT es muy fácil, solo debes crear tu propia cuenta para imprimir un QR como este, pegarlo en la puerta de tu gimnasio y pedirle a tus clientes que escaneen al ingresar.',
                      },
                      {
                        title: 'Bienvenido 👋',
                        element: document.querySelector('.menu-container'),
                        intro: 'Cada vez que un cliente escanee el QR, podrás de forma instantanea ver su hora de ingreso, nombre, foto y si pagó o no su cuota, en la sección "accesos" en el menú.',
                      }
                    ],
                  })
                  .start();
            
            }, 500); // Ajusta el retraso si es necesario
      }, 
      downloadQRCode(itemId,name) {
        // Encuentra el contenedor del código QR usando el ID dinámico
        const qrContainer = document.querySelector(`#${itemId} img`);

        if (!qrContainer) {
          console.error("No se encontró el elemento de imagen para el QR:", itemId);
          return;
        }

        // Obtiene el src de la imagen en base64 y crea un enlace de descarga
        const base64 = qrContainer.src;
        const link = document.createElement("a");
        link.href = base64;
        link.download = `qr_${name}.png`;
        link.click();
      },
    printQR(id) {
      const printContents = document.getElementById(id).innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // Esto recarga la página para restaurar el contenido original
    },
    async getAll() {
      try {
        const response = await getAllQrAction();
        this.jsonData.qrs = response.qrs;
        this.selectItem(this.jsonData.qrs[0]); 
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    selectItem(item){
        this.selected = item._id;
        this.$emit("selected",item)
            const targetElement = document.getElementById('anonymousorder');
          // Verificar si el elemento existe
        // Desplazar hacia el elemento de forma suave
        targetElement.scrollIntoView({ behavior: 'smooth' });
         
    }
  }
};
</script>


<style scoped>


.qrcode{
  width:100% !important;  
}
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
.md-select {
  width: auto; /* Ajusta el tamaño del select */
}
</style>
