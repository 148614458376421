<template>
  <div class="md-layout" :class="mobile? 'table-mobile':''">
    <!-- Botón para añadir nuevo usuario -->
    <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100">
      <md-button v-if="!showNewQrForm" class="add-user md-primary md-sm" @click="addUser()">
        + {{ $t('addUser') }}
      </md-button> 
    </div>
    <!-- Formulario para añadir nuevo usuario -->
    <div class="md-layout" v-if="showNewQrForm">
      <div class="md-layout-item md-size-50 md-small-size-100 mt-4 p-2">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
              <md-icon>group</md-icon>
            </div>
            <h4 class="title">{{ useredit.email ? $t('edit') : $t('addUser') }}</h4>
          </md-card-header>
          <md-card-content>
            <NewUserForm 
              v-if="this.role.includes('client')"
              :hide="hide"
              :user="useredit" 
              :required="['name', 'role', 'identifier']"
              :roles="role" 
              :type="isRestaurant() ? 'restaurant' : 'gym'"
              @userCreated="userCreated($event)"
            />
            <NewUserForm 
              v-else
              :hide="hide"
              :user="useredit" 
              :required="['name', 'role']"
              :roles="role" 
              :type="isRestaurant() ? 'restaurant' : 'gym'"
              @userCreated="userCreated($event)"
            />
            <md-button class="md-danger md-sm w-100" @click="showNewQrForm = !showNewQrForm">
              {{ $t('cancel') }}
            </md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <!-- Tabla de usuarios -->
    <div class="md-layout-item md-size-100 mt-4 p-2" v-if="!showNewQrForm">

    <div class="card w-100 p-4 mb-2" v-if="showFilters">
      <h6>Buscar</h6>
       <md-field>
        <label>{{ $t('name') }}</label>
        <md-input type="text" v-model="searchQuery"></md-input>

      </md-field>
       <md-field>
        <label>{{ $t('identifier') }}</label>
        <md-input type="text" v-model="identifier"></md-input>

      </md-field>
        <md-button class="md-button md-sm md-primary" @click="search()">
          <md-icon>search</md-icon>{{$t('search')}}
        </md-button>
        <md-button class="md-button md-sm md-secondary" @click="getAllNoActives()">
          <md-icon>refresh</md-icon>borrar filtros
        </md-button>
    </div>
    <div class="card w-100 p-4 mb-2" v-if="isGym() && showFilters">
      <div>
        <h6>Acciones</h6>
        <div class="d-inline p-2" v-if="false">
        <md-button class="md-button md-sm md-primary" :disabled="!hasSelected()">
          <md-icon>campaign</md-icon>Difundir un mensaje
        </md-button>
        </div>
        <div class="mt-3">
        <SendEmail v-if="false"/>

          <md-card v-if="isGym()">
            <md-card-content>
              <Excel @reload="getAll()"></Excel>
            </md-card-content>
          </md-card>
        </div>

      </div>
    </div>

      <md-card>
        <md-card-content> 

        <div class="d-inline">
        <md-button v-if="role.includes('client')" class="md-button md-sm" :class="selectAll ? 'md-danger':'md-primary'" @click="toggleSelectAll()">
          <span v-if="selectAll">{{$t('Deseleccionar')}}</span>
           <span v-else>{{$t('selectAll')}}</span>
        </md-button>
        
        
        <div v-if="role.includes('client')" >
        <div>

        <md-button v-if="isGym() && (options.onlyactives == 'false' || !options.onlyactives)" class="md-button md-sm md-primary" @click="getOnlyActives()">
          <span>Ver solo usuarios activos</span>
        </md-button>
          <small class="text-secondary">(aquellos que registran acceso en los ultimos 60 días)</small>
        </div>

        <md-button v-if="isGym() && options.onlyactives == 'true'" class="md-button md-sm md-secondary" @click="getAllNoActives()">
          <md-icon>refresh</md-icon>Ver todos los usuarios
        </md-button>
        </div>
    

            <div class="py-3" v-if="showFilters">
        <div class="mx-1">
          <h5> Total: {{total}} usuarios <span v-if="options.onlyactives == 'true'">activos</span></h5>
        </div>
              <md-field class="md-form-group">
                <md-icon>settings</md-icon>
                <label>Asignar un plan de pago</label>

                <md-select  v-model="myconfig_id"  

                              @md-selected="choseConfig()" 
                  class="px-3">
                  <md-option  class="ml-3"
                    v-for="conf in config" 
                    :key="conf._id" 
                    :value="conf._id">

                        {{conf.price}}$ {{ getPaymentMessage(conf) }}
                  
                   
                  </md-option>
                </md-select>
              </md-field>
              <div>
                <small v-if="myconfig" class="text-success">{{getPaymentMessage(myconfig)}}</small>
              </div>
            </div>


              <md-button class="md-button md-sm md-success" @click="asignPlan()" v-if="selectedUsers.length > 0" :disabled="!myconfig">
                  Asignar este plan
              </md-button>
          <div class="my-3" v-if="page">
            <div class="pagination">
              <pagination v-model="infoPagination" @input="newPage($event)" :page-count="totalPages">
              </pagination>
            </div>
          </div>
        </div>
          <md-table 
            v-if="!mobile"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
          v-model="users" 
          
             class="paginated-table table-striped table-hover">
            <md-table-row slot="md-table-row"  slot-scope="{ item }" :class="isSelected(item._id) ? 'selected selectedrow':''">
            
              <md-table-cell  :md-label="$t('select')" >
                <md-checkbox v-model="selectedUsers" :value="item._id"></md-checkbox>
                  <div   class="mt-2">
                    <md-button class="md-button md-sm md-primary w-100 mb-2 edituser" @click="editUser(item)">
                      {{$t('edit')}}
                    </md-button>
                </div>
                <div   class="d-block">
                    <md-button class="md-button md-sm md-danger w-100 mb-2" @click="confirmDelete(item._id)">
                      {{$t('delete')}}
                    </md-button>
                </div> 
              </md-table-cell>

              <md-table-cell  :md-label="$t('name')" md-sort-by="name">
                <avatar class="float-left" :logo="item.avatar" :small="true"></avatar>
                <span>{{ item.name }}</span>
              </md-table-cell>
              <md-table-cell :md-label="$t('role')" v-if="item.role != 'client'">
                {{$t(item.role+'_role')}}
              </md-table-cell>
              <md-table-cell v-if="isGym() && item.role == 'client'" :md-label="'Características'">
                <div>
                  <div class="mb-1">
                    <small v-if="item.price">
                      <md-icon>paid</md-icon>
                       {{item.price}}<small>$</small>
                      <small v-if="item.paymentType == 'month'">
                        {{ $t('perMonth') }}
                      </small>
                    </small>

                     <div v-if="item.access < 0 || item.access == null" class=" mt-1">
                        <small   class=" text-primary">
                          <md-icon>meeting_room</md-icon>
                          <md-icon>all_inclusive</md-icon>
                        </small>
                      </div>
                      <div   v-if="item.cupos < 0" class=" mt-1">
                        <small>
                          <md-icon>self_improvement</md-icon>
                          <md-icon>all_inclusive</md-icon>
                        </small>
                      </div>
                      <div  v-if="item.cupos >= 0  || item.cupos == null"  class=" mt-1">
                      <small>
                        <md-icon>self_improvement</md-icon>
                        <span v-if="!item.cupos">0</span> 
                        <span v-else>{{ item.cupos }}</span>
                      </small>
                      </div>
                      <div  v-if="item.access >= 0"  class=" mt-1">
                      <small>
                        <md-icon>meeting_room</md-icon>
                        <span v-if="!item.access">0</span> 
                        <span v-else>{{ item.access }}</span>
                      </small>
                      </div>
                      <div  class=" mt-1">
                      <small v-if="item.identifier">
                        <md-icon>key</md-icon>
                        <span>{{ item.identifier }}</span>
                      </small>
                      </div>


                  </div>
                </div>
              </md-table-cell>
              <md-table-cell :md-label="'Plan de pago'" v-if="item.role == 'client'">
                <small v-for="product in item.products" class="text-primary">{{product.name}} ({{product.price}}$)</small>
                <small v-if="!item.products || !item.products.length" class="text-secondary"><strong>Sin plan asignado</strong> (Atención: sin plan asignado no es posible generar solicitudes de pago)</small>

              </md-table-cell>
              <!-- Nueva Columna 6: Fecha de creación -->
              <md-table-cell  :md-label="'Fecha de creación'"  md-sort-by="createdAt">
                <span>{{ new Date(item.createdAt).toLocaleDateString() }}</span>
              </md-table-cell>
              <!-- Nueva Columna 6: Fecha de creación -->
              <md-table-cell  :md-label="$t('paymentDate')"  md-sort-by="paymentDate"  v-if="item.role == 'client'">
                <div v-if="item.paymentDate && item.paymentType == 'month'">
                 
                  <span v-if="!isPaymentDateValid(item)" class="text-danger">
                    <md-icon class="text-danger">warning</md-icon>  {{ localDate(item.paymentDate) }}  {{ $t('paymentPending') }}
                  </span>      
                  <span v-else>
                    <md-icon>check</md-icon>   {{ localDate(item.paymentDate) }}
                  </span>
                <div v-if="item.role == 'client'">
                        <button class="btn btn-success markaspaid  btn-sm float-right cursor-pointer" @click="markAsPaid(item,'approved')" v-if="isMoreThanOneMonthAway(item.paymentDate)">
                        <small>  <md-icon class="text-white">check</md-icon> {{ $t('markAsPaid') }}</small>
                        </button>
                        <button  class="btn btn-primary mt-2 assignplan btn-sm float-right cursor-pointer" @click="markAsPaid(item,'pending')" v-if="isMoreThanOneMonthAway(item.paymentDate) && item.products && item.products.length" :disabled="existePaymentDateIgual(item.orders,item.paymentDate)">
                        <small v-if="!existePaymentDateIgual(item.orders,item.paymentDate)"><md-icon class="text-white">payment</md-icon>Generar una solicitud de pago</small>
                         <small v-else> <md-icon class="text-white">payment</md-icon>Solicitud generada</small>
                        </button>
                        <button  class="btn btn-primary mt-2 assignplan btn-sm float-right cursor-pointer" @click="markAsPaid(item,'pending')" v-if="!item.products || !item.products.length" :disabled="true">
                       
                         <small > <md-icon class="text-white">payment</md-icon>Sin plan asignado</small>
                        </button>
                        <button class="btn btn-success  btn-sm"  disabled="true" v-if="!isMoreThanOneMonthAway(item.paymentDate)">
                          <small><md-icon class="text-white">check</md-icon> Falta más de un mes</small>
                        </button>
                </div>

                </div>
              </md-table-cell>
              <md-table-cell  :md-label="$t('phone')">
                <a class="text-primary" :href="'tel:'+item.phone">{{ item.phone }}</a>
              </md-table-cell>
              <md-table-cell   :md-label="$t('email')">
                <span>{{ item.email }}</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
           <md-table v-else 

            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
           v-model="users" class="table-full-width">
            <md-table-row slot="md-table-row" slot-scope="{ item }" :class="isSelected(item._id) ? 'selected selectedrow':''">
            <md-table-cell class=""   :md-label="$t('name')" md-sort-by="name">     
                  <div class="mb-2">   
                    <div class="float-right m-2">
                    <md-button class="mb-1 md-just-icon md-button md-primary edituser" @click="editUser(item)">
                      <md-icon class="text-white">edit</md-icon>
                    </md-button>
                </div>
                <div class="float-right m-2">
                    <md-button class="md-just-icon md-button md-danger" @click="confirmDelete(item._id)">
                      <md-icon class="text-white">delete</md-icon>
                    </md-button>
                </div>
                 

                  <md-checkbox v-model="selectedUsers" :value="item._id" class="float-left m-0" ></md-checkbox>
                 <avatar :logo="item.avatar" :small="true" class=" m-2 float-left"></avatar>
                     
                      <div v-if="item.role == 'client'  && item.paymentType == 'month'">
                        <span class=" text-danger mb-2" v-if="!isPaymentDateValid(item)" >
                          <md-icon class="text-danger">warning</md-icon> {{$t('paymentPending')}}
                        </span>      

                      </div>


{{ item.name }}
                </div> 
               




               
                      <small v-for="product in item.products" class="text-primary">{{product.name}} ({{product.price}}$)</small>
                      <div  v-if="item.role == 'client'" class="my-2">
                      


                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.price">
                          <strong>Pago:</strong> {{item.price}}$ 
                      <small v-if="item.paymentType == 'month'">
                        {{ $t('perMonth') }}
                      </small>
                        </span> 
                      </div>

                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.paymentType == 'month'">
                          <strong>{{$t('identifier')}}:</strong> {{item.identifier}}
                        </span> 
                      </div>
              
                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.paymentType == 'month'">
                          <strong>{{$t('email')}}:</strong> {{item.email}}
                        </span> 
                      </div>
              
              
                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.paymentType == 'month'">
                          <strong>{{$t('phone')}}:</strong> {{item.phone}}
                        </span> 
                      </div>
              
                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.paymentType == 'month'">
                          <strong>{{ $t('paymentDate') }}:</strong><span :class="!isPaymentDateValid(item) ? 'text-danger' : '' "> {{ localDate(item.paymentDate) }} </span>
                        </span> 
                      </div> 


                      
                     
                    
                    </div>

                   
                <div v-if="item.role == 'client'">
                        <button class="btn btn-success m-2 btn-sm cursor-pointer markaspaid" @click="markAsPaid(item,'approved')" v-if="isMoreThanOneMonthAway(item.paymentDate)">
                        <small>  <md-icon class="text-white">check</md-icon> {{ $t('markAsPaid') }}</small>
                        </button>
                        <button  class="btn btn-primary  btn-sm  cursor-pointer assignplan" @click="markAsPaid(item,'pending')" v-if="isMoreThanOneMonthAway(item.paymentDate) && item.products && item.products.length" :disabled="existePaymentDateIgual(item.orders,item.paymentDate)">
                        <small v-if="!existePaymentDateIgual(item.orders,item.paymentDate)"><md-icon class="text-white">payment</md-icon>Generar una solicitud de pago</small>
                         <small v-else> <md-icon class="text-white">payment</md-icon>Solicitud generada</small>
                        </button>
                        <button  class="btn btn-primary  btn-sm cursor-pointer assignplan" @click="markAsPaid(item,'pending')" v-if="!item.products || !item.products.length" :disabled="true">
                       
                         <small > <md-icon class="text-white">payment</md-icon>Sin plan asignado</small>
                        </button>
                        <button class="btn btn-success  btn-sm"  disabled="true" v-if="!isMoreThanOneMonthAway(item.paymentDate)">
                          <small><md-icon class="text-white">check</md-icon> Falta más de un mes</small>
                        </button>
                </div>
             
              </md-table-cell>
              <md-table-cell v-if="item.role != 'client'">
                
                        <small  class="badge badge-primary mt-1">
                          <md-icon class="text-white">email</md-icon>
                        {{ item.email }}
                        </small>
                      <div v-if="item.phone">
                        <small  class="badge badge-success mt-1">
                          <md-icon class="text-white">phone</md-icon>
                        {{ item.phone }}
                        </small>
                      </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="my-3" v-if="page">
            <div class="pagination">
              <pagination v-model="infoPagination" @input="newPage($event)" :page-count="totalPages">
              </pagination>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { createUserAction,deleteUserAction, markAsPaidAction ,assignPlanAction} from "@/pages/Users/actions";
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";
import Swal from 'sweetalert2';
import {getPaymentConfigAction} from "@/pages/Auth/actions";
import { uploadImageAction } from "@/pages/Products/actions";
import permissionsMixin from '@/mixins/permissionsMixin';
import { format } from 'date-fns';
import { SendEmail } from "@/components/Emails";
import { Pagination } from "@/components";


import {
  Avatar,
  Excel
} from "@/components";


export default {
  name: 'Gym', // Asegúrate de que el nombre coincida si planeas usar este componente recursivamente
  mixins: [permissionsMixin],
  components: {
    NewUserForm,
    Avatar,
    Excel,
    SendEmail,
    Pagination
  },
  computed: {
    userAdmin() {
      return this.users.filter(user => user.role == 'restaurant' || user.role == 'gym');
    },
    userOthers() {
      return this.users.filter(user => user.role != 'restaurant' && user.role != 'gym');
    },
  },  
  data() {
    return {
      isLoading:false,
      myconfig:false,
      myconfig_id:false,
      defaultPagination: 1,
      infoPagination: 1,
      config:[],
      errorMessage: false,
      subdomain: false,
      currentSort: "paymentDate",
      currentSortOrder: "desc",
      useredit: {},
      searchQuery: '', // Para búsqueda
      identifier: '', // Para búsqueda
      selectedUsers: [],
      showNewQrForm: false,
      selectAll: false,
      editingQrId: null,
      originalName: '',
      hide: ['password'],
    };
  },
  props:{
      users: Array,
      role: String,
      mobile: Boolean,
      showFilters:Boolean,
      page:Number,
      totalPages:Number,
      total:Number,
      searchQueryDefault:'',
      options: {},
  },
  created() {
    this.subdomain = window.location.hostname;
    if(this.searchQueryDefault){
      this.searchQuery = this.searchQueryDefault.name
      this.identifier = this.searchQueryDefault.identifier
    }
    
    if(!this.role.includes('client')){
     this.hide = []
    }
    this.getPaymentConfig()

      this.infoPagination = this.page
  },
  methods: {  
    getOnlyActives(){
      this.$emit("search",{onlyactives: true})
    },
    getAllNoActives(){
      this.$emit("search",{onlyactives: false})
    },
    search(){
      this.$emit("search",{name: this.searchQuery, identifier: this.identifier})
    },
    existePaymentDateIgual(orders, paymentDate) {
       if(orders.length == 0){
        return false;
       }

      return orders.some(order => order.paymentDate === paymentDate);
    },
    reset(){
      this.searchQuery = {};
      this.getAll();
    },
    newPage(page){ 
        this.$emit("newPage",page);
    },
    async asignPlan(){
      const productName = this.getPaymentMessage(this.myconfig)
      this.myconfig.productName = productName
      await assignPlanAction(this.myconfig,this.selectedUsers);
       Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
      this.$emit("refresh");
    },
    choseConfig(){
        this.myconfig = this.config.find(element => element._id === this.myconfig_id);
    },
    getPaymentMessage(conf) {
      let productName = '';
      if (conf.paymentType == 'month' && conf.access == -1 && conf.cupos == -1) {
        productName = this.$t("unlimitedMonthlyPayment");
      } else if (conf.paymentType == 'month' && conf.access == -1 && conf.cupos > -1) {
        productName = this.$t("unlimitedMonthlyPaymentWithQuota", { cupos: conf.cupos });
      } else if (conf.paymentType == 'month' && conf.access > -1 && conf.cupos == -1) {
        productName = this.$t("limitedAccessUnlimitedQuota", { access: conf.access });
      } else if (conf.paymentType == 'month' && conf.access > -1 && conf.cupos > -1) {
        productName = this.$t("limitedMonthlyPayment", { cupos: conf.cupos, access: conf.access });
      } else if (conf.paymentType != 'month' && conf.access > -1 && conf.cupos > -1) {
        productName = this.$t("oneTimePayment", { cupos: conf.cupos, access: conf.access });
      }
      return productName;
    },
    async getPaymentConfig(){
        const response = await getPaymentConfigAction();
        this.config = response.data
    },
    hasSelected(){
      if(this.selectedUsers.length){
        return true;
      }
      return false;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "asc") {
        return b[sortBy].localeCompare(a[sortBy]);
        }
          return a[sortBy].localeCompare(b[sortBy]);
      });
    },
    isMoreThanOneMonthAway(paymentDate) {
      const today = new Date();
      const oneMonthLater = new Date(today.setMonth(today.getMonth() + 1)); // Suma 1 mes a la fecha actual

      const paymentDateObj = new Date(paymentDate);

      return paymentDateObj < oneMonthLater; // Devuelve true si paymentDate es más de un mes en el futuro
    },
    isPaymentDateValid(item) {

      if(item.paymentType == 'month'){
        return !item.hasToPay
      }else{
        return true;
      }

    },
    addUser(){
      this.useredit = {}
      this.showNewQrForm = !this.showNewQrForm;
    },  
    editUser(item){
        this.showNewQrForm = false;
        this.useredit = item
        this.showNewQrForm = true;
    },
    addOneMonthToDate(paymentDate) {
      const dateObj = new Date(paymentDate); // Convierte la fecha a un objeto Date

      dateObj.setMonth(dateObj.getMonth() + 1); // Suma 1 mes

      return dateObj;
    },
    markAsPaid(item,status){

    if(!item.price){
       Swal.fire({
          title: "Debes insertar el monto a cobrar",
          text: "Edita el usuario e inserta el precio que el cliente debe pagar para que podamos registrarlo y mostrarte estadísticas financieras",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Aceptar",
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          return;
        })
    }else{

      if(status == 'pending'){
         Swal.fire({
          title: "Generar una solicitud de pago",
          text: "El cliente podrá ver esta solicitud en su cuenta y pagarla con Mercado Pago o cualquier método de pago elegido. Las solicitudes de pago se generan automáticamente solo para los clientes que registran accesos al gimnasio en los últimos 2 meses.",
          icon: 'payment',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Aceptar",
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            this.paySelected(item,status);
          }
        })
      }else{
            Swal.fire({
          title: "Al marcar como pagado la fecha de pago se modifica agregandole un mes más.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Aceptar",
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            this.paySelected(item,status);
          }
        })
      }

  
    }
   

    },
    async paySelected(item,status) {
        //const newDate = this.addOneMonthToDate(item.paymentDate);
        //item.paymentDate = newDate.toISOString()
        try {
          const response = await markAsPaidAction(item._id,status);
          Swal.fire({
            title: response.message,
            icon: response.status,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('ok')
          })
          this.getAll()

        } catch (error) {
          this.errorMessage = this.$t('userExists');
        }
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PP');  // Formato de fecha y hora según la hora local del navegador
    },
    showDelete(userId){
      if(userId == localStorage.getItem('userId')){
        return false;
      }
      return true;
    },
    async confirmDelete(userId) {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUser(userId);
          Swal.fire(
            this.$t('deleted'),
            'success'
          )
        }
      })
    },
    async deleteUser(userid) {
      try {
        const response = await deleteUserAction(userid);
         this.getAll()
      } catch (error) {
        console.log(error.message);
      }
    },

    async userCreated(event){
          this.showNewQrForm = false
          Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
      this.$emit("refresh");
        
    },
    async getAll() {
      this.$emit("refresh");
    },
    isSelected(id){
      return this.selectedUsers.includes(id)
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selectedUsers = this.users.map(user => user._id);
      } else {
        this.selectedUsers = [];
      }
    }
  }
};
</script>
<style>

.selected{
  background-color: rgba(0, 0, 0, 0.1) !important;  
}

.badge > .md-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    font-size: 18px !important;
    font-weight: 100;
}
.table-mobile .md-table-head-label {
    padding-left: 21px !important;
}

.paymentwidth{
    width: 163px !important;
    max-width: 1000px !important;
    float: left;
}
</style>

<style scoped>
.deactivated {
  opacity: 0.2;
}
.qrcode {
  width: 50px !important;  
}
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
.md-select {
  width: auto; /* Ajusta el tamaño del select */
}
.table-full-width .md-table-cell:first-child {
    padding-left: 10px;
}
</style>

<style>
.pagination{
    width:auto;
    margin:0 auto;
}
.md-ripple > span {
    position:relative !important;
    overflow: inherit !important;
}
.md-select-menu .md-list-item .md-list-item-content span {
    word-wrap: break-word;
    white-space: normal;
}
</style>
