<template>
  <div class="md-layout">
    <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100" v-if="role != 'employee'">
      <md-button class="md-primary md-sm" @click="showNewQrForm = !showNewQrForm">+ {{ $t('addQr') }}</md-button>
      <md-button v-if="showSaveButton" class="md-primary md-sm" @click="confirmSaveAll">{{ $t('saveAll') }}</md-button>
    </div>
    <!-- Formulario para añadir nuevo QR -->
    <div v-if="showNewQrForm" class="md-layout-item md-size-100 mt-4">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>add_circle</md-icon>
          </div>
          <h4 class="title">{{ $t('addNewQr') }}</h4>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label>{{ $t('qrName') }}</label>
            <md-input v-model="newQr.name" :placeholder="$t('qrName')"></md-input>
          </md-field>
          <md-field v-if="isRestaurant()">
            <label>{{ $t('menu') }}</label>
            <md-select v-model="newQr.menuId">
              <md-option v-for="menu in menus" :key="menu._id" :value="menu._id">{{ menu.name }}</md-option>
            </md-select>
          </md-field>
          <md-button class="md-primary md-sm" @click="addNewQr">{{ $t('add') }}</md-button>
          <md-button class="md-danger md-sm" @click="cancelNewQr">{{ $t('cancel') }}</md-button>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100 mt-4">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>qr_code</md-icon>
          </div>
          <h4 class="title">{{ $t('qrCodes') }}</h4>
          <p class="p-2 md-text text-secondary" v-if="isRestaurant()"><md-icon>chat</md-icon> "{{$t('qrhelp')}}"</p>
          <p class="p-2 md-text text-secondary" v-if="isGym()"><md-icon>chat</md-icon> "{{$t('stickQRMessage')}}"</p>
        </md-card-header>
        <md-card-content>
          <md-table v-model="jsonData.qrs" class="table-full-width" v-if="role != 'employee'">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell :md-label="$t('name')" :class="item.disabled ? 'deactivated':''">
                <div>
                  <div  class="qrcode"> 
                    <router-link class="md-primary" :to="{ path: 'menu/'+item._id }" v-if="isRestaurant()">  
                     <qrcode-vue :id="'qr-container-'+item._id" :value="'https://'+subdomain+'/menu/'+item._id" :size="100"></qrcode-vue>
                    </router-link> 
                    <router-link class="md-primary" :to="{ path: 'access/'+item._id }" v-if="isGym()">  
                     <qrcode-vue  :id="'qr-container-'+item._id" :value="'https://'+subdomain+'/access/'+item._id" :size="100"></qrcode-vue>
                    </router-link> 
                  </div>  
                    <router-link class="md-primary" :to="{ path: 'menu/'+item._id }" v-if="isRestaurant()">  
                     <md-icon class="md-primary">reply</md-icon>   {{ $t('viewMenu') }}
                    </router-link> 
                  </div>
                  <small class="text-secondary">{{$t('scanphone')}}</small>
                  
                  <div class="w-100" >
                  <span class="badge badge-primary cursor-pointer" @click="downloadQRCode('qr-container-'+item._id,item.name)">
                    <md-icon class="text-white">download</md-icon> {{$t('download')}}
                  </span>
                    <md-switch v-model="item.disabled" @change="saveAllChanges()">
                      <span v-if="!item.disabled">{{ $t('deactivateQR') }}</span>
                      <span v-else>{{ $t('activateQR') }}</span>
                    </md-switch>
                  </div>
              </md-table-cell>
              <md-table-cell  class="pt-4">
                  <div class="w-100">
                    <label v-if="!editingQrId">{{ item.name }}</label>
                    <md-button v-if="!editingQrId" class="md-just-icon md-simple md-primary" @click="startEditing(item)">
                      <md-icon>edit</md-icon>
                    </md-button>
                    <div class="pt-4" v-if="editingQrId === item._id">
                      <md-field>
                        <label>{{ $t('name') }}</label>
                        <md-input v-model="item.name" @blur="stopEditing(item)" @keyup.enter="stopEditing(item)"></md-input>
                      </md-field>
                      <md-button class="md-icon-button md-primary py-1" @click="stopEditing(item)">
                        <md-icon>check</md-icon>
                      </md-button>
                      <md-button class="md-icon-button md-danger py-1" @click="cancelEditing">
                        <md-icon>close</md-icon>
                      </md-button>
                    </div>
                  </div>
                  <div>
                    <md-field v-if="isRestaurant()">
                      <label>{{ $t('menu') }}</label>
                      <md-select  v-model="item.menu._id" @md-close="updateQrMenu(item)">
                        <md-option v-for="menu in menus" :key="menu._id" :value="menu._id">{{ menu.name }}</md-option>
                      </md-select>
                    </md-field>
                  </div>
              </md-table-cell>
              <md-table-cell>
                <div>
                  <md-button class="md-danger card-icon md-just-icon" @click="confirmDeleteQr(item._id)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>

          
          <div  v-if="role == 'employee'">
            <div v-for="item in jsonData.qrs" class="md-layout-item md-size-50 float-left" :key="item._id">
                <div :class="item.disabled ? 'deactivated':''">
                    <router-link class="md-primary" :to="{ path: 'menu/'+item._id }">  
                      <h4>   <md-icon class="md-primary">restaurant</md-icon>   {{ item.name }} </h4>
                      <div  class="qrcode"> 
                        <router-link class="md-primary" :to="{ path: 'menu/'+item._id }">  
                         <qrcode-vue :value="'https://'+subdomain+'/menu/'+item._id" :size="100"></qrcode-vue>
                        </router-link> 
                      </div>   
                    </router-link>     
                </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode-vue';
import { getAllQrAction, saveAllAction } from "@/pages/Qrs/actions";
import { getAllMenuAction } from "@/pages/Menu/actions";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';


export default {
  mixins: [permissionsMixin],
  components: {
    QrcodeVue
  },
  created() {
    this.role = localStorage.getItem('role');
    this.subdomain = window.location.hostname;
    this.getAll();
    this.getAllMenus();
  },
  data() {
    return {
      role: false,
      subdomain: false,
      jsonData: {
        qrs: []
      },
      menus: [],
      showNewQrForm: false,
      showSaveButton: false,
      newQr: {
        name: '',
        menuId: ''
      },
      editingQrId: null,
      originalName: ''
    };
  },
  watch: {
    jsonData: {
      handler() {
        this.showSaveButton = true;
      },
      deep: true
    }
  },
  methods: {

      downloadQRCode(itemId,name) {
        // Encuentra el contenedor del código QR usando el ID dinámico
        const qrContainer = document.querySelector(`#${itemId} img`);

        if (!qrContainer) {
          console.error("No se encontró el elemento de imagen para el QR:", itemId);
          return;
        }

        // Obtiene el src de la imagen en base64 y crea un enlace de descarga
        const base64 = qrContainer.src;
        const link = document.createElement("a");
        link.href = base64;
        link.download = `qr_${name}.png`;
        link.click();
      },
    async getAll() {
      try {
        const response = await getAllQrAction();
        this.jsonData.qrs = response.qrs.map(qr => {
          if (!qr.menu) {
            qr.menu = {};
          }
          return qr;
        });
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async getAllMenus() {
      try {
        const response = await getAllMenuAction();
        this.menus = response.menus;
      } catch (error) {
        console.log(error.message);
      }
    },
    async updateQrMenu(qr) {
      try {
        const menu = this.menus.find(menu => menu._id === qr.menu._id);
        qr.menu = menu;
        this.showSaveButton = true;
      } catch (error) {
        console.log(error.message);
      }
    },
    startEditing(item) {
      this.editingQrId = item._id;
      this.originalName = item.name;
    },
    stopEditing(item) {
      this.editingQrId = null;
      this.markChanges();
      this.saveAllChanges()
    },
    cancelEditing() {
      const item = this.jsonData.qrs.find(qr => qr._id === this.editingQrId);
      if (item) {
        item.name = this.originalName;
      }
      this.editingQrId = null;
      this.originalName = '';
    },
    markChanges() {
      this.showSaveButton = true;
    },
    async confirmDeleteQr(qrId) {

      if(this.isDemo()){
        return;
      }
      
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.jsonData.qrs = this.jsonData.qrs.filter(qr => qr._id !== qrId);
          this.deleteQr(qrId);
          Swal.fire(
            this.$t('deleted'),
            this.$t('yourQrHasBeenDeleted'),
            'success'
          )
        }
      })
    },
    async deleteQr(qrId) {

      if(this.isDemo()){
        return;
      }
      try {
        this.saveAllChanges()
        this.showSaveButton = true;
      } catch (error) {
        console.log(error.message);
      }
    },
    addNewQr() {
      if(this.isDemo()){
        return;
      }

      if (this.isGym() && !this.newQr.name) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('pleaseFillOutAllFields'),
        });
        return;
      }

      if (this.isRestaurant() && (!this.newQr.name || !this.newQr.menuId)) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('pleaseFillOutAllFields'),
        });
        return;
      }

      const newQr = {
        _id: Date.now().toString(), // Generar un ID único temporalmente
        name: this.newQr.name,
        menu: this.menus.find(menu => menu._id === this.newQr.menuId)
      };
      this.jsonData.qrs.push(newQr);
      this.resetNewQrForm();
      this.saveAllChanges()
    },
    resetNewQrForm() {
      this.newQr = {
        name: '',
        menuId: ''
      };
      this.showNewQrForm = false;
    },
    cancelNewQr() {
      this.resetNewQrForm();
    },
    confirmSaveAll() {
      if(this.isDemo()){
        return;
      }
      
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('doYouWantToSaveAllChanges'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesSaveIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveAllChanges();
          Swal.fire(
            this.$t('saved'),
            this.$t('yourChangesHaveBeenSaved'),
            'success'
          )
        }
      })
    },
    async saveAllChanges() {
      try {

        
        if(this.isDemo()){
          return;
        }
      
        await saveAllAction(this.jsonData);
        this.showSaveButton = false;

        this.getAll();
        this.getAllMenus();
      } catch (error) {
        console.log(error.message);
      }
    }
  }
};
</script>

<style>
.cursor-pointer{
  cursor:pointer;
}
</style>
<style scoped>
.menuselect{
  min-width:150px;
}
.deactivated{
      opacity: 0.2;
}
.qrcode{
  width:50px !important;  
}
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
.md-select {
  width: auto; /* Ajusta el tamaño del select */
}
</style>
