import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

// Crear o actualizar una encuesta
export const createOrUpdateSurvey = async (surveyData) => {
  try {
    const { data } = await wencesApi.post(`api/survey/create`, surveyData);
    return {
      ok: true,
      survey: data,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 403) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.error(error);
    throw new Error("Error al crear o actualizar la encuesta");
  }
};

// Obtener todas las encuestas
export const getSurveys = async () => {
  try {
    const { data } = await wencesApi.get(`api/survey`);
    return {
      ok: true, 
      surveys: data,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.error(error);
    throw new Error("Error al obtener las encuestas");
  }
};

// Obtener todas las encuestas
export const getActiveSurveys = async () => {
  try {
    const { data } = await wencesApi.get(`api/survey/active`);
    return {
      ok: true, 
      surveys: data,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.error(error);
    throw new Error("Error al obtener las encuestas");
  }
};

// Eliminar una encuesta
export const deleteSurvey = async (surveyId) => {
  try {
    await wencesApi.delete(`api/survey/${surveyId}/delete`);
    return {
      ok: true,
      message: "Encuesta eliminada con éxito",
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 403) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.error(error);
    throw new Error("Error al eliminar la encuesta");
  }
};

// Crear una respuesta para una encuesta
export const createAnswerSurvey = async (answerData) => {
  try {
    const { data } = await wencesApi.post(`api/survey/answer`, answerData);
    return {
      ok: true,
      answer: data,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 403) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.error(error);
    throw new Error("Error al crear la respuesta de la encuesta");
  }
};