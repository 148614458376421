<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" v-if="role != 'employee'">
      <md-card v-if="dashboard">
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
            <h4 class="title">{{ $t('settings') }}</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 p-2" v-if="isRestaurant()">
              <label class="md-form-label">
                {{ $t('email') }}
              </label>
                <small class="text-secondary">({{$t('sendNotifications')}})</small>
              <md-field>
                <md-input v-model="emailBusiness" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-20 p-2"  v-if="isRestaurant()">
              <label class="md-form-label">
                {{ $t('currency') }}</label>
              <md-field>
                <md-select v-model="currency">
                  <md-option v-for="currency in currencyOptions" :key="currency.value" :value="currency.value">
                    {{ currency.symbol }} ({{ currency.value }})
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-80  p-2"  v-if="isRestaurant()">
               <label class="md-form-label">{{ $t('position_currency') }}</label>
                <md-field>
                  <md-select v-model="currencyPosition">
                    <md-option value="after">12{{currencySymbol}}</md-option>
                    <md-option value="before">{{currencySymbol}}12</md-option>
                  </md-select>
                </md-field>
            </div>

            <div class="w-100 mb-4"  v-if="isRestaurant()">
           

          
              <div class="card p-2">
                <p>{{ $t('shipping_cost') }}</p>
                <small>{{$t('shippingdesc')}}</small>
                <md-field>
                  <label class="">Precio</label>
                  <md-input v-model="shippingCostPrice" :placeholder="$t('Precio')" type="number"></md-input>
                </md-field>
                <md-field>
                  <label>Países con envío gratuito</label>
                  <md-select v-model="shippingFreeCountries" multiple  required>
                    <md-option v-for="country in countryCodes" :key="country.code" :value="country.code">
                       {{ country.flag }} {{ country.name }} 
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>


           
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-button class="md-raised md-primary" @click="saveSettings">{{ $t('save') }}</md-button>
            </div>
          </div>

          </div>

          <div class="card float-left p-4 mt-3" v-if="role == 'restaurant'">
            <Stripe></Stripe>
          </div>

         
        </md-card-content>
      </md-card>


    </div>
  </div>
</template>

<script>
import { saveAction, getSettingsAction } from "@/pages/Auth/actions";
import Swal from 'sweetalert2';
import { Stripe } from "@/pages";
import permissionsMixin from '@/mixins/permissionsMixin';
import { getPriceAction,oauthAction } from "@/pages/MercadoPago/actions";
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";
import { getMyProfileAction } from "@/pages/Users/actions";


export default {
  name: "Settings",
  components: {
    Stripe,
    NewUserForm
  },
  mixins: [permissionsMixin],

  props: {
    dashboard: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      role: false,
      nameBusiness: "",
      emailBusiness: "",
      currency: "",
      useredit:false,
      currencyPosition: "",
      subdomain: "",
      currencyOptions:[],
      shippingFreeCountries:[],
      shippingCostPrice:0
    };
  },

  computed: {
    currencySymbol() {
      const selectedCurrency = this.currencyOptions.find(option => option.value === this.currency);
      return selectedCurrency ? selectedCurrency.symbol : '';
    }
  },

  created() {
  
    this.countryCodes = this.$countryCodes;
    this.getMyProfile();
    this.currencyOptions = this.$currencies;
    this.subdomain = window.location.hostname;
    this.role = localStorage.getItem('role');
    const mercadoapagooauth = localStorage.getItem('mercadoapagooauth');
    if(this.$route.path == '/mercadopago/confirm' && this.$route.query.code && this.$route.query.state){
      const state = this.$route.query.state;
      if(mercadoapagooauth == state){
        this.registerToken()
      }
    }
  },

  methods: {
    async getMyProfile(){
      this.useredit = await getMyProfileAction();
    },
    async registerToken(){
      await oauthAction(this.$route.query.code);
      
      Swal.fire({
        title: this.$t('success'),
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
           this.$router.push('/payments'); 
      })
    },

    userCreated(event){

        Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
    },
    async fetchBusinessData() {
      try {
        const response = await getSettingsAction();
        const data = response.result;
        this.emailBusiness = data.email === "contact@rodisio.com" ? "" : data.email || "";
        this.nameBusiness = data.name || "";
        this.currency = data.currency || "usd"; // valor predeterminado
        this.currencyPosition = data.currencyPosition || "after";
        this.shippingCostPrice = data.shippingCostPrice;
        this.shippingFreeCountries = data.shippingFreeCountries;
        
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    },
    async saveSettings() {

      if(this.isDemo()){
        return;
      }

      const data = {
        name: this.nameBusiness,
        email: this.emailBusiness,
        currency: this.currency,
        currencyPosition: this.currencyPosition,
        shippingCostPrice: this.shippingCostPrice,
        shippingFreeCountries: this.shippingFreeCountries
      };

      try {
        await saveAction(data);
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('business_info_updated'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } catch (error) {
        console.error("Error saving business data:", error);
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('error_updating_business_info'),
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
  },

  async mounted() {
    this.fetchBusinessData();
  }
};
</script>


<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.form-control-static {
  margin-top: 6px;
}
</style>
