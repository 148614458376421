<template> 
  <div>

          <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
          <div class="w-100 text-center customborder" v-if="users.length == 0 && !isLoading">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>
    <Gym 
      v-if="!isLoading && isGym()" 
      :users="users" 
      :role="['client']"  
      :page="page"
      :total="pagination.totalDocuments"
      :totalPages="pagination.totalPages"
      :showFilters="true" 
      :mobile="isMobile" 
      :searchQueryDefault="search"
      :options="pagination"
      @refresh="refresh()" 
      @search="searchNow($event)"
      @newPage="newPage($event)"/>

    <restaurant v-if="!isLoading && isRestaurant() && users.length"  :users="users" />
  </div>
</template>

<script>
import { getAllUsersAction ,getAllClientsAction } from "@/pages/Users/actions";


import { Restaurant, Gym, GymMobile } from "@/pages/Users";
import permissionsMixin from '@/mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  components: {
    Restaurant,
    GymMobile,
    Gym
  },
  data() {
    return {
      isMobile: false,
      users:[],
      isLoading:false,
      page:1,
      pagination: false,
      search: false
    };
  },
  created(){
    this.getAll(this.page);
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768; // Detecta si es un dispositivo móvil (puedes ajustar el ancho según tus necesidades)
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    refresh(){
      if(!this.page){
        this.page = 1;
      }
      if(!this.search){
       this.search = '';
      }
        this.getAll(this.page);
    },
    async getAll(page) {
      try {
        this.isLoading = true
        let response ="";
        if(this.isGym()){
          response = await getAllClientsAction(page,this.search);
        }else{
          response = await getAllUsersAction(page,this.search);
        }
        this.users = response.users;
        this.pagination = response.pagination;
        if(this.pagination.totalPages == 1){
          this.page = false;
        }
        this.isLoading = false
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    newPage(page){
      this.page = page
      this.getAll(page);
    },
    searchNow(search){
      this.search = search
      this.getAll(1);
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>


