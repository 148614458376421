<template>
 <div>

  <md-card>
          <md-card-header>
              <div class="card-icon p-1 bg-danger">
                <md-icon class="">sports_volleyball</md-icon>
              </div>
              <h4 class="title mt-2">Elementos personalizados</h4>
              <p>Agrega los elementos presentes en tu establecimiento como máquinas, cintas de correr, bicicletas, y relacionalos a los ejercicios que se pueden hacer con él. Luego podrás pegar el QR en cada elemento para que tus clientes puedan saber que ejercicios se pueden realizar y entender como se hacen.</p>
          </md-card-header>

          <md-card-content>
           <ElementList/>

          </md-card-content>
        </md-card>
  

       
  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import ElementList from "@/pages/Training/ElementList.vue";
import CreateElement from "@/pages/Training/CreateElement.vue";



export default {
  mixins: [permissionsMixin],
  data() {
    return {
      open:false
    };
  },
  components: {
    ElementList,
    CreateElement
  },
  async created() {
  
  },
  methods:{
 
  
  },
};
</script>

