<template>
  <div>
  <FormValidate
    v-if="formData.length"
    :inputs="formInputs"
    :requiredFields="['users']"
    :buttonLabel="$t('save')"
    :submitonchange="submitonchange"
    :cancelbutton="cancelbutton"
    :acceptbutton="acceptbutton"
    :nocontext="nocontext"
    @formSubmitted="handleFormSubmit"
    @cancel="cancel()"
  />
  </div>
</template>

<script>
import FormValidate from '@/components/FormValidate.vue';
import { createElementAction, updateElementAction } from '@/pages/Training/actions';
import Swal from "sweetalert2";
import { getAllClientsAction } from "@/pages/Users/actions";

export default {
  name: "MyClients",
  components: {
    FormValidate
  },
  created(){
    this.getAll()
  },
  props: {
    onlyselect: Boolean,
    cancelbutton:Boolean,
    acceptbutton:Boolean,
    nocontext:Boolean,
    submitonchange:Boolean
  },
  data() {
    return {
      preloadedData:false,
      users:[],
      formData:[],
      formInputs: [
        {
          name: 'users',
          label: this.$t('users'),
          type: 'select',
          multiple: true,
          options: []
        },
      ],
      title: this.$t('asignplanning')
    };
  },
  methods: {
    async getAll() {
      try {
        const response = await getAllClientsAction();
        this.users = response.users;
        this.formData = this.users.map(item => ({
          value: item._id,
          text: item.name
        }));
        this.formInputs[0].options = this.formData
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    cancel(){
      this.$emit("cancel")
    },
    loadPreloadedData(data) {
     
    },
    async handleFormSubmit(formData) {
       this.$emit('result',formData)
    }
  }
};
</script>
